import React, { Component } from "react";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import { Form, Button } from "react-bootstrap";
import Newcode from "./Newcode";
import CompLoc from "./CompLoc";
import EmodQuestions from "./EmodQuestions";
import { resetEligibility } from "../../../utils/eligibility_helpers";
import InputGroup from "react-bootstrap/InputGroup";

import {
  submitComrRate,
  dropReadFile,
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
  zeroDefault,
  handleCheck,
  getMinMax,
  removeStateAggregateData,
} from "../../../utils/form_cmprRates";

import {
  salesManagerLoginCheck,
  adminPersonLoginCheck,
  agentPersonLoginCheck,
} from "../../../utils/form_ques";

import {
  submitCompPro,
  // stepOneGet,
  sendEmail,
  resendOTPMethod,
  verifyOTP,
  reset,
  setStateAndCity,
  // getEmodStatesData,
  setStateAndCity1,
  setStateAndCity2,
} from "../../../utils/form_compPro";

import {
  clearErr,
  validEmail,
  onlyAlpha,
  clearDelete,
  initializeComponents,
} from "../../../utils/common";

import {
  cookie,
  cookie2,
  setCookie,
  setProspectDetails,
  deleteAddress,
  setAddressDetails,
  setHistoricalClaims,
} from "../../../utils/session_data";

import { clearErrHistoricalClaims } from "../../../utils/common";

import NewProgress from "../../../newcomponent/common/NewProgress";

import $ from "jquery";
import SmallLoader from "../../common/SmallLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiURL, awsUrl, awsUrl2 } from "../../../config";
import store from "../../../redux/store/index";
import StateList from "../../common/StateList";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
import { companyProfileAddQuestions } from "../../../utils/ques_list";
import Allques from "../../subcompo/sections-quote/Allques";
import Context from "../../../contexts/Context";
import BrokerQues from "./BrokerQues";
import UploadAcordFile from "../../../newcomponent/common/UploadAcordFile";
import AdditonalEntity from "./AdditonalEntity";
import IncludeExclude from "./IncludeExclude";

let ismobile = window.innerWidth < 990;

var stateCity = require("../../../utils/state_city.json");
const numeral = require("numeral");
const uuidv4 = require("uuid/v4");
let myConfig = new AWS.Config();
myConfig.update({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAJB3XUTJTLJMI4BHA",
    secretAccessKey: "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",
  },
});
var prospect, addressCount, loggedin;

class FormCompPro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      role: "",
      childrenLoc: {},
      error: false,
      errorMsg: "",
      showCompanyProfile: true,
      showLocationAndPayroll: true,
      state_class_code: {},
      agentpersonCheck: false,
      agentPersonName: "",
      companyProfile: {
        companyName: {},
        descriptionOfOperations: {},
        firstName: {},
        lastName: {},
        yearsInBusiness: {},
        phoneNumber: {},
        entityType: {},
        empLiabilityLimit: {},
        emailId: {},
        effectiveDate: { value: moment() },
        fein: {},
        street1: {},
        zipCode1: {},
        cityName1: {},
        state1: { value: "fl" },
        street2: {},
        zipCode2: {},
        cityName2: {},
        state2: {},
        // website: {},
      },
      sameAsPrimary: false,
      quoteSubmitted: false,
      additionalEntity: {
        checked: false,
        entityList: [],
      },
      emod: {
        emodValue: "",
      },
      includedExcludedObject: {
        includeExcludeList: [],
        includeCodeCodeError: {},
        includeExcludeEligibilityCheck: {},
      },
      govCCPremium: {},
      feinAgencyMap: {},
      feinAgencyError: "",
      // historicalClaimsError: false,

      // historicalClaims: {},
      // historicalClaimsCheck: {},
      // historicalClaimsErrorStore: {},
      // selDateBus: null,
      // selDateEff: moment(),
      // selDateExp: moment().add(1, "year"),
      // header: {},
      // uploadingFile: false,
      // awaitingFile: false,
      // urlSuccess: false,
      // urlFail: false,
      // basicInfoFail: false,
      // stateInfoFail: false,
      // signedUrl: "",
      // showAdditionalQuestions: true,
      // companyProfileAddQuestions: companyProfileAddQuestions,
      // requestedPricing: {
      //   checked: true,
      //   adminPercent: "",
      //   perCKdollar: "",
      //   WeeklySelect: "b",
      // },
      // emodQuestions: {},
      // emodStatesData: {},
      // otpVerified:
      //   sessionStorage.getItem("otpVerified") === "true" ? true : false,
      // PreUwCheck: false,
      // PreUwPersonName: "",
      // salesManagerCheck: false,
      // salesManagerPersonName: "",
      // brokerDetails: {
      //   brokerQuestionValue: false,
      //   brokerdropdownSelected: null,
      //   brokerPercentage: null,
      // },
    };

    // this.setStateAndCity = setStateAndCity.bind(this);
    this.setStateAndCity1 = setStateAndCity1.bind(this);
    this.setStateAndCity2 = setStateAndCity2.bind(this);

    // this.setHistoricalClaims = setHistoricalClaims.bind(this);
    // this.handleCheck = handleCheck.bind(this);
    // this.clearErr = clearErrHistoricalClaims.bind(this);
    // this.zeroDefault = zeroDefault.bind(this);
  }

  setStateAndCity = (zipCode, index) => {
    let {
      childrenLoc,
      //  emodStatesData,
      state_class_code,
    } = this.state;
    if (!zipCode) return;
    axios

      .get(awsUrl2 + "/api/getStateAndCity/" + zipCode + "/all")
      .then((res) => {
        const data = res.data;

        if (data !== `"Data not found"`) {
          let locationIndex = index;
          let fieldName = "state";
          let prevState;
          if (
            fieldName === "state" &&
            childrenLoc[locationIndex][fieldName].value
          ) {
            prevState = childrenLoc[locationIndex][fieldName].value;

            //cc_delete
            for (let classCodeIndex in childrenLoc[locationIndex]
              .classCodesInfo) {
              let currState = childrenLoc[locationIndex].state.value || "";
              try {
                if (
                  currState.toLowerCase() in state_class_code &&
                  state_class_code[currState.toLowerCase()].val &&
                  childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                    "classCodeDescription"
                  ]
                ) {
                  let class_code_desc = childrenLoc[locationIndex]
                    .classCodesInfo[classCodeIndex]["classCodeDescription"]
                    ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                        "classCodeDescription"
                      ].value
                    : undefined;
                  if (class_code_desc) {
                    let class_code = class_code_desc.split(":")[0].trim();
                    if (
                      state_class_code[currState.toLowerCase()].cc_dsc &&
                      class_code in
                        state_class_code[currState.toLowerCase()].cc_dsc
                    ) {
                      let locToUpdate, ccToUpdate;
                      for (let locIndx in childrenLoc) {
                        if (childrenLoc[locIndx].state.value === currState) {
                          let ccInfo = childrenLoc[locIndx].classCodesInfo;
                          for (let ccIndx in ccInfo) {
                            let cc_desc;
                            try {
                              cc_desc =
                                ccInfo[ccIndx]["classCodeDescription"].value;
                            } catch (e) {}
                            if (cc_desc) {
                              let cc = cc_desc.split(":")[0].trim();
                              if (Number(cc) === Number(class_code)) {
                                childrenLoc[locIndx].classCodesInfo[ccIndx][
                                  "pricing"
                                ] = {
                                  value:
                                    state_class_code[currState.toLowerCase()]
                                      .cc_dsc[class_code],
                                  disabled: true,
                                };
                                if (
                                  !locToUpdate &&
                                  !ccToUpdate &&
                                  Number(locIndx) !== Number(locationIndex)
                                ) {
                                  locToUpdate = locIndx;
                                  ccToUpdate = ccIndx;
                                }
                              }
                            }
                          }
                        }
                      }
                      if (locToUpdate && ccToUpdate) {
                        childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                          "pricing"
                        ].disabled = false;
                        if (
                          !(
                            "cc_dsc" in
                            state_class_code[currState.toLowerCase()]
                          )
                        ) {
                          state_class_code[currState.toLowerCase()].cc_dsc = {};
                        }
                        try {
                          state_class_code[currState.toLowerCase()].cc_dsc[
                            class_code
                          ] =
                            childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                              "pricing"
                            ].value;
                        } catch (error) {}
                      } else {
                        delete state_class_code[currState.toLowerCase()].cc_dsc[
                          class_code
                        ];
                      }
                    }
                  }
                }
              } catch (error) {}
            }
            //cc_delete
          }
          childrenLoc[index].state = { value: data.state };
          childrenLoc[index].cityName = { value: data.city };

          let stateList = [];
          for (let addressBlockNo in childrenLoc) {
            if (childrenLoc[addressBlockNo].state.value) {
              stateList.push(
                childrenLoc[addressBlockNo].state.value.toLowerCase()
              );
            }
          }
          if (fieldName === "state") {
            let value = data.state;
            try {
              for (let addressBlockNo in childrenLoc) {
                if (childrenLoc[addressBlockNo].state.value) {
                  if (
                    prevState &&
                    prevState === childrenLoc[addressBlockNo].state.value
                  ) {
                    childrenLoc[addressBlockNo].suta.disabled = false;
                    childrenLoc[addressBlockNo].clientsuta.disabled = false;
                    prevState = "#####";
                  }
                }
              }

              if (prevState && prevState !== "#####") {
                delete state_class_code[prevState.toLowerCase()];
              }

              let stateSet = new Set(stateList);

              for (let stateIndx in state_class_code) {
                if (!stateSet.has(stateIndx.toLowerCase())) {
                  delete state_class_code[stateIndx];
                }
              }
            } catch (error) {}

            try {
              if (!(value.toLowerCase() in state_class_code)) {
                state_class_code[value.toLowerCase()] = {
                  ...state_class_code[value.toLowerCase()],
                  val: childrenLoc[locationIndex].suta.value
                    ? childrenLoc[locationIndex].suta.value
                    : "",
                  val2: childrenLoc[locationIndex].clientsuta.value
                    ? childrenLoc[locationIndex].clientsuta.value
                    : "",
                };
                childrenLoc[locationIndex].suta.disabled = false;
                childrenLoc[locationIndex].clientsuta.disabled = false;
              } else {
                if (state_class_code[value.toLowerCase()].val) {
                  childrenLoc[locationIndex]["suta"] = {
                    value: state_class_code[value.toLowerCase()].val,
                    disabled: true,
                  };
                } else {
                  childrenLoc[locationIndex]["suta"] = {
                    value: "",
                    disabled: false,
                  };
                }
                if (state_class_code[value.toLowerCase()].val2) {
                  childrenLoc[locationIndex]["clientsuta"] = {
                    value: state_class_code[value.toLowerCase()].val2,
                    disabled: true,
                  };
                } else {
                  childrenLoc[locationIndex]["clientsuta"] = {
                    value: "",
                    disabled: false,
                  };
                }
              }
            } catch (error) {
              console.log(
                "error in setStateAndZip inside state_class_code calculations"
              );
            }
          }

          if (stateList.length > 0) {
            let statesData = {
              ncciStates: [],
              actingNcciStates: [],
              indBureauStates: [],
            };
            axios
              .post(
                awsUrl2 + "/api/getExpModStateCategory",
                JSON.stringify({ states: stateList })
              )
              .then((response) => {
                statesData = response.data;
              })
              .catch((error) => {
                console.log("error", error);
              })
              .finally(() => {
                // let newEmodData = getEmodStatesData(
                //   statesData,
                //   emodStatesData || {}
                // );
                this.setState({
                  childrenLoc,
                  // emodStatesData: newEmodData
                });
              });
          } else {
            // emodStatesData = {};
            this.setState({
              childrenLoc,
              // emodStatesData
            });
          }
        }
      })
      .catch((err) => {
        console.log("Err in get state with zip code:", err);
      });
  };

  setUWQues = (quesData) => {
    let { companyProfileAddQuestions } = JSON.parse(JSON.stringify(this.state));
    for (let quesIndex in companyProfileAddQuestions) {
      if (quesData[quesIndex].resp === "Y") {
        companyProfileAddQuestions[quesIndex].response = true;
      } else {
        companyProfileAddQuestions[quesIndex].response = false;
      }
      companyProfileAddQuestions[quesIndex].remarks =
        quesData[quesIndex].remarks;
    }
    this.setState({ companyProfileAddQuestions });
  };

  // setting the manual premium
  govCCPremiumHandler = (value) => {
    this.setState({ govCCPremium: value });
  };

  // settign the true false in additional Entity
  setAdditionalEntityHandler = (event, value) => {
    let { additionalEntity } = JSON.parse(JSON.stringify(this.state));
    if (additionalEntity.entityList.length == 0) {
      additionalEntity.entityList.push({
        companyName: { value: "", error: "" },
        fein: { value: "", error: "" },
      });
    }

    additionalEntity.checked = value;
    if (additionalEntity.checked === false) {
      additionalEntity.entityList = [];
      // additionalEntity.entityList.push({
      //   companyName: { value: "", error: "" },
      //   fein: { value: "", error: "" },
      // });
    }

    this.setState({ additionalEntity });
  };

  // adding the additional Entity  functionality
  addAdditionalEntityHandler = () => {
    let { additionalEntity } = JSON.parse(JSON.stringify(this.state));
    additionalEntity.entityList.push({
      companyName: { value: "", error: "" },
      fein: { value: "", error: "" },
    });
    this.setState({ additionalEntity });
  };

  // remove the additional Entity Functionality
  removeAdditionalEntityHandler = (index) => {
    let { additionalEntity } = JSON.parse(JSON.stringify(this.state));
    additionalEntity.entityList.splice(index, 1);

    if (additionalEntity.entityList.length === 0) {
      additionalEntity.checked = false;
    }

    this.setState({ additionalEntity });
  };

  // set additional Entity company Name and fein

  setAdditionalEntityvalueHandler = (e, valueName, index) => {
    let { additionalEntity } = JSON.parse(JSON.stringify(this.state));
    additionalEntity.entityList[index][
      valueName
    ].value = e.target.value.trimStart();

    if (e.target.value !== "") {
      additionalEntity.entityList[index][valueName]["error"] = false;
    }

    this.setState({ additionalEntity });
  };

  // set feincheck in Additional Entity
  setAdditionalEntityFein = () => {
    let { additionalEntity } = JSON.parse(JSON.stringify(this.state));
    this.setState({ additionalEntity });
  };

  // add include and exclude new row

  addIncludeExcludeHandler = () => {
    let { includedExcludedObject } = JSON.parse(JSON.stringify(this.state));
    includedExcludedObject.includeExcludeList.push({
      officerName: { value: "", error: "" },
      ownerShip: { value: "", error: "" },
      includExclude: { value: "", error: "" },
      classCode: { value: "", error: "" },
    });
    this.setState({ includedExcludedObject });
  };
  // remove the include exclude row

  removeIncludeExcludeHandler = (index) => {
    let { includedExcludedObject } = JSON.parse(JSON.stringify(this.state));
    // deleting the  class code eligibility check index once it's removed from the object

    if (
      Object.keys(includedExcludedObject["includeExcludeEligibilityCheck"])
        .length > 0 &&
      includedExcludedObject["includeExcludeEligibilityCheck"][index]
    ) {
      delete includedExcludedObject["includeExcludeEligibilityCheck"][index];
    }

    includedExcludedObject.includeExcludeList.splice(index, 1);
    includedExcludedObject.includeCodeCodeError = {};
    this.setState({ includedExcludedObject });
  };

  // setting the value of include exclude field
  setincludeExcludeValueHandler = (e, values, index) => {
    let { includedExcludedObject } = JSON.parse(JSON.stringify(this.state));
    includedExcludedObject.includeExcludeList[index][values][
      "value"
    ] = e.target.value.trimStart();
    if (e.target.value !== "") {
      includedExcludedObject.includeExcludeList[index][values]["error"] = false;
    }

    this.setState({ includedExcludedObject });
  };

  // updateQuesList = (e, key, value) => {
  //   let { companyProfileAddQuestions } = JSON.parse(JSON.stringify(this.state));
  //   let index = e.target.id.split("-")[1];
  //   companyProfileAddQuestions[index][key] = value;
  //   this.setState({ companyProfileAddQuestions });
  // };
  // updateLastThreeQuesList = (e, key, value) => {
  //   let { companyProfileAddQuestions } = JSON.parse(JSON.stringify(this.state));

  //   let index = e.target.name[2] - 1;
  //   // console.log("+", index, "+", key, "+", value);
  //   companyProfileAddQuestions[index][key] = value;
  //   this.setState({ companyProfileAddQuestions });
  // };

  // updateQuesList_sales_flow = (e, key, value) => {
  //   let { companyProfileAddQuestions } = JSON.parse(JSON.stringify(this.state));
  //   let index = 2;
  //   companyProfileAddQuestions[index][key] = value;
  //   this.setState({ companyProfileAddQuestions });
  // };

  addYearToDate = (dt) => {
    dt.exp = Object.assign({}, dt);
    dt.exp.eff.add(1, "year");
    return dt;
  };
  handleDateChangeBus = (date) => {
    this.setState({
      selDateBus: date,
    });
  };
  handleDateChangeEff = (date) => {
    let { companyProfile } = this.state;
    if (date) {
      companyProfile.effectiveDate.value = date;

      this.setState({ companyProfile });
    }
  };
  handleDateChangeExp = (date) => {
    this.setState({
      selDateExp: date,
    });
  };

  // handleOptionChange = (e, key, value) => {
  //   let { requestedPricing } = this.state;
  //   requestedPricing["WeeklySelect"] = value;
  //   this.setState({
  //     ...requestedPricing,
  //     // companyProfileAddQuestions: companyProfileAddQuestions,
  //   });
  // };

  // brokerQuestionResponse = (e, response) => {
  //   let brokerDetails = { ...this.state.brokerDetails };
  //   brokerDetails.brokerQuestionValue = response;
  //   this.setState({ brokerDetails });
  // };

  // brokerDropdownResponse = (selectedOption) => {
  //   let brokerDetails = { ...this.state.brokerDetails };
  //   // console.log("value got selecet", selectedOption);
  //   brokerDetails.brokerdropdownSelected = selectedOption;
  //   this.setState({
  //     brokerDetails,
  //   });
  // };

  // brokerPercentageResponse = (e) => {
  //   let brokerDetails = { ...this.state.brokerDetails };
  //   // console.log("value got selecet", e.target.value);
  //   brokerDetails.brokerPercentage = e.target.value;
  //   this.setState({
  //     brokerDetails,
  //   });
  // };

  componentWillMount() {
    window.scrollTo(0, 0);
    // stepOneGet();
    // prospect = cookie();
    // addressCount = cookie2();
    // loggedin = sessionStorage.getItem("isLoggedIn");
    // let rand = Math.random();
    let dupChildrenLoc = {};
    // let initialCountOfAddress = 1;
    // if (prospect && addressCount && loggedin) {
    //   initialCountOfAddress = addressCount;
    // }
    for (let i = 0; i < 1; i++) {
      let rand = Math.random();
      dupChildrenLoc[i] = {
        number: i,
        key: rand,
        classCodesInfo: { 0: {} },
        state: { value: "fl" },
        zipCode: {},
        cityName: {},
        street: {},
        suta: {},
        clientsuta: {},
      };
    }
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
    $("#loader").css("display", "block");
  }

  // componentDidUpdate() {
  //   const { childrenLoc } = this.state;
  //   clearDelete(childrenLoc, "addressBlock");
  //   if (prospect && addressCount && loggedin) {
  //     setProspectDetails();
  //     setAddressDetails();
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.props.quoteSubmitted !== prevProps.quoteSubmitted)
      this.setState({ quoteSubmitted: this.props.quoteSubmitted });
  }

  setInputData = (currProspect) => {
    try {
      let { state_class_code } = this.state;
      let {
        govClassCodeObject,
        childrenLoc,
        companyProfile,
        emod,
        additionalEntity,
        includedExcludedObject,
      } = currProspect;

      if (!childrenLoc || Object.keys(childrenLoc).length === 0) {
        let rand = Math.random();
        childrenLoc = {
          "0": {
            number: 0,
            key: rand,
            classCodesInfo: { "0": {} },
            state: { value: "fl" },
            // zipCode: {},
            // cityName: {},
            // street: {},
            // suta: {},
            // clientsuta: {},
          },
        };
      }

      let primaryKeys = ["cityName", "street", "state", "zipCode"];
      let formStage = sessionStorage.getItem("formStage");
      let sameAsPrimary = true;
      // for (let key of primaryKeys) {
      //   if (
      //     companyProfile?.[`${key}1`]?.value !==
      //     companyProfile?.[`${key}2`]?.value
      //   ) {
      //     sameAsPrimary = false;
      //     break;
      //   }
      // }

      //create state and cc_desc key in state_class_code map
      for (let stateIndx in childrenLoc) {
        if (
          childrenLoc[stateIndx] &&
          childrenLoc[stateIndx].state &&
          childrenLoc[stateIndx].state.value &&
          !(
            childrenLoc[stateIndx].state.value.toLowerCase() in state_class_code
          )
        ) {
          let currstate = childrenLoc[stateIndx].state.value.toLowerCase();
          let ccinfo = childrenLoc[stateIndx].classCodesInfo;
          if (currstate in state_class_code) continue;

          state_class_code[currstate] = {
            val:
              childrenLoc[stateIndx].suta && childrenLoc[stateIndx].suta.value
                ? childrenLoc[stateIndx].suta.value
                : "",
            val2:
              childrenLoc[stateIndx].clientsuta &&
              childrenLoc[stateIndx].clientsuta.value
                ? childrenLoc[stateIndx].clientsuta.value
                : "",
            cc_dsc: {},
          };

          for (let ccinfoIndx in ccinfo) {
            let ccObject = ccinfo[ccinfoIndx];
            if (ccObject) {
              let cc =
                ccObject.classCodeDescription &&
                ccObject.classCodeDescription.value
                  ? ccObject.classCodeDescription.value.split(":")[0]
                  : "";
              if (!(cc in state_class_code[currstate].cc_dsc)) {
                state_class_code[currstate].cc_dsc[cc] =
                  ccObject.pricing && ccObject.pricing.value
                    ? ccObject.pricing.value
                    : "";
              }
            }
          }
        }
      }

      if (companyProfile?.effectiveDate?.value) {
        companyProfile.effectiveDate.value = moment(
          companyProfile.effectiveDate.value,
          "YYYY/MM/DD"
        );
      } else {
        companyProfile.effectiveDate = {};

        companyProfile.effectiveDate.value = moment();
      }

      // setting the primary location if selected

      if (currProspect?.sameAsPrimary) {
        this.state.sameAsPrimary = true;
      }
      if (
        currProspect.companyProfile.empLiabilityLimit.value == "" ||
        currProspect.companyProfile.empLiabilityLimit.value == "Please Select"
      ) {
        currProspect.companyProfile.empLiabilityLimit.value = "100-100-500";
      }

      // setting the historical claims
      // if (currProspect) {
      //   if (currProspect.companyProfile.effectiveDate.value) {
      //     let currYear =
      //       new Date(
      //         currProspect?.companyProfile?.effectiveDate.value
      //       ).getFullYear() ||
      //       new Date(companyProfile?.effectiveDate.value).getFullYear() ||
      //       new Date().getFullYear();
      //     console.log("currYear: ", currYear);
      //     let historyLimitYear;
      //     if (currProspect && currProspect.companyProfile.yearsInBusiness) {
      //       historyLimitYear =
      //         currYear - currProspect.companyProfile.yearsInBusiness.value;
      //     } else {
      //       historyLimitYear =
      //         currYear - this.state.companyProfile.yearsInBusiness.value;
      //     }

      //     let historicalClaimsCheck = {};
      //     for (let i = 0; i < 5; i++) {
      //       if (currYear >= historyLimitYear) {
      //         historicalClaimsCheck[currYear] = false;
      //       } else {
      //         historicalClaimsCheck[currYear] = true;
      //       }
      //       currYear--;
      //     }
      //     this.setHistoricalClaims();
      //   }
      //   this.setState({ historicalClaimsCheck, historicalClaims });
      // }

      // if (requestedPricing) {
      //   if (!requestedPricing?.["WeeklySelect"]) {
      //     requestedPricing["WeeklySelect"] = "b";
      //   }
      //   this.setState({ requestedPricing });
      // }
      // if (brokerDetails) {
      //   this.setState({ brokerDetails });
      // }
      // if (companyProfileAddQuestions) {
      //   for (let question of companyProfileAddQuestions) {
      //     console.log(question);
      //     if (question.name !== "qc3") {
      //       if (question.response === "Y") {
      //         question.response = true;
      //       } else {
      //         question.response = false;
      //       }
      //     }
      //   }

      //   for (let i = 0; i < this.state.companyProfileAddQuestions.length; i++) {
      //     if (companyProfileAddQuestions?.[i]?.["name"] == undefined) {
      //       companyProfileAddQuestions[i] = {};
      //       companyProfileAddQuestions[i][
      //         "name"
      //       ] = this.state.companyProfileAddQuestions[i]["name"];
      //       companyProfileAddQuestions[i][
      //         "ques"
      //       ] = this.state.companyProfileAddQuestions[i]["ques"];
      //       companyProfileAddQuestions[i][
      //         "response"
      //       ] = this.state.companyProfileAddQuestions[i]["response"];
      //     }
      //   }

      //   this.setState({ companyProfileAddQuestions });
      // }

      this.setState(
        {
          childrenLoc: childrenLoc,
          companyProfile: companyProfile,
          emod: emod,
          additionalEntity: additionalEntity,
          includedExcludedObject: includedExcludedObject,
        },
        () => {
          if (formStage === "one" && formStage !== "gov_cc") {
            $("#loader").css("display", "none");
          }
        }
      );
    } catch (error) {
      console.log("error: ", error);
    }
  };

  checkSameAsPrimary = () => {
    let { companyProfile } = this.state;
    let primaryKeys = ["cityName", "street", "state", "zipCode"];
    let sameAsPrimary = true;
    for (let key of primaryKeys) {
      if (
        companyProfile?.[`${key}1`]?.value !==
        companyProfile?.[`${key}2`]?.value
      ) {
        sameAsPrimary = false;
        break;
      }
    }

    return sameAsPrimary;
  };

  validateStateCityAndZip = async (childrenLoc) => {
    try {
      function fetchStateAndCity(currObj) {
        new Promise((resolve, reject) => {
          let _zipCode = currObj.zipCode.value;
          let _cityName = currObj.cityName.value;
          let _state = currObj.state.value;

          if (!_zipCode) {
            resolve();
          }

          axios
            .get(awsUrl2 + "/api/getStateAndCity/" + _zipCode + "/all")

            .then((res) => {
              let { state, city } = res.data;

              if (_state !== state) {
                currObj.zipCode.error = true;
                currObj.cityName.error = true;
              } else if (_cityName !== city) {
                currObj.cityName.error = true;
              }

              resolve();
            })
            .catch((err) => {
              resolve();
            });
        });
      }

      let stateAndCityPromiseList = [];

      for (let key in childrenLoc) {
        let currObj = childrenLoc[key];

        stateAndCityPromiseList.push(fetchStateAndCity(currObj));
      }

      await Promise.allSettled(stateAndCityPromiseList)
        .then((res) => {
          console.log("details checked ", res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  componentDidMount = async () => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let govClassCodedescription =
      currProspect?.["govClassCodeObject"]?.["govClassCode"];
    console.log("govClassCodedescription: ", govClassCodedescription);
    let checkGovCCpresent = govClassCodedescription ? true : false;

    let agentId = sessionStorage.getItem("agentID");

    if (currProspect?.companyProfile) {
      this.setInputData(currProspect);
    } else {
      if (checkGovCCpresent) {
        let { childrenLoc } = JSON.parse(JSON.stringify(this.state));
        childrenLoc[0].classCodesInfo[0] = {};
        childrenLoc[0].classCodesInfo[0].classCodeDescription = {};
        childrenLoc[0].classCodesInfo[0].classCodeDescription[
          "value"
        ] = govClassCodedescription;
        childrenLoc[0].classCodesInfo[0].govClassCodeCheck = {};
        childrenLoc[0].classCodesInfo[0].govClassCodeCheck["value"] = true;

        this.setState({ childrenLoc });
      }
    }
    // let mailCampaignUserCheck = checkMailCampaignUser();
    // let isHomeCampaign = sessionStorage.getItem("homeCampaign") ? true : false;

    // admin login check
    let {
      adminName,
      adminNameAttr,
      adminpersonCheck,
      username,
    } = await adminPersonLoginCheck();
    if (adminpersonCheck) {
      this.setState({
        adminpersonCheck,
        adminName,
        username,
      });
    }
    // agent login check
    let {
      agentpersonName,
      agentpersonCheck,
      agentpersonNameAttr,
      agentusername,
    } = await agentPersonLoginCheck();
    if (agentpersonCheck) {
      this.setState({
        agentpersonName,
        agentpersonCheck,
        agentpersonNameAttr,
        username: agentusername,
      });
    }

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");
    let campaignName = urlParams.get("campaignName");
    let generalDomainNames = [
      "gmail",
      "yahoo",
      "aol",
      "mac",
      "cox",
      "frontier",
      "msn",
      "gammana",
      "hotmail",
      "pacbell",
      "att",
      "sbcglobal",
      "windstream",
      "verizon",
      "comcast",
      "ampcocontracting",
    ];
    let domain;
    let viewButton = urlParams.get("viewButton");
    let { companyProfile, includedExcludedObject } = JSON.parse(
      JSON.stringify(this.state)
    );
    const formStage = sessionStorage.getItem("formStage");
    if (
      agentId &&
      (formStage === "gov_cc" || formStage === "one" || formStage === "two")
    ) {
      axios
        .get(awsUrl2 + "/api/getAgentDBData/" + agentId)
        .then(async (response) => {
          if (response.data.eligibilityCheck) {
            let eligibility_check = JSON.parse(
              JSON.stringify(response?.data?.eligibilityCheck)
            );

            this.props.setEligibilityCheck(eligibility_check);
          }
        })
        .catch((error) => console.log("Error in fetch data from DB", error));
      //   this.setState({ companyProfile });
    }

    let len = includedExcludedObject?.includeExcludeList?.length;

    if (len == 0) {
      includedExcludedObject = {
        includeExcludeList: [
          {
            officerName: { value: "", error: "" },
            ownerShip: { value: "", error: "" },
            includExclude: { value: "", error: "" },
            classCode: { value: "", error: "" },
          },
        ],
      };
      this.setState({ includedExcludedObject });
    }

    // if (
    //   currProspect.companyProfile.empLiabilityLimit.value == "" ||
    //   currProspect.companyProfile.empLiabilityLimit.value == "Please Select"
    // ) {
    //   currProspect.companyProfile.empLiabilityLimit.value = "100-100-500";
    // }

    // let preUnderwriter = sessionStorage.getItem("preUnderwriter");
    // if (user && !mailCampaignUserCheck) {
    //   $("#loader").css("display", "block");
    //   user = user.toLowerCase();
    //   let tempBusinessName = user.split("@")[1].split(".");
    //   tempBusinessName.pop();
    //   let business_name = tempBusinessName.join(".");
    //   if (generalDomainNames.includes(business_name)) {
    //     domain = user.split("@")[0];
    //   } else {
    //     domain = business_name;
    //   }
    //   let currDomain = sessionStorage.getItem("domain");
    // let timestamp = Math.floor(Date.now()).toString();
    // sessionStorage.setItem("visitTimestamp", timestamp);
    //   sessionStorage.setItem("landingEmail", user);

    //   let domainResp = await axios.get(awsUrl2 + "/api/getDomain/" + user);
    //   if (domainResp.data && domainResp.data.domain) {
    //     domain = domainResp.data.domain;
    //   }
    //   sessionStorage.setItem("domain", domain);

    //   axios
    //     .get(awsUrl + "/api/getUserDataStatus/" + domain + "/quote")
    //     .then(async (response) => {
    //       let requestBody = {};

    //       if (response && response.data) {
    //         sessionStorage.setItem("emailCampaignUser", true);
    //         let carrierBasedData = response.data.carrierBasedData;
    //         console.log("**inside here");
    //         let currProspect = {},
    //           sortKeyList = [],
    //           quoteData = { uuid: "", date: "" },
    //           address = [];
    //         for (let carrier in carrierBasedData) {
    //           for (let line in carrierBasedData[carrier]) {
    //             let lineObj = carrierBasedData[carrier][line];

    //             let currProspectDetails = lineObj.currProspectDetails;
    //             let preUwData = lineObj.preUwData;

    //             if (lineObj.preUwFiles)
    //               sessionStorage.setItem(
    //                 "preUwFiles",
    //                 JSON.stringify(lineObj.preUwFiles)
    //               );

    //             if (lineObj.dashboardFiles)
    //               sessionStorage.setItem(
    //                 "dashboardFiles",
    //                 JSON.stringify(lineObj.dashboardFiles)
    //               );

    // let companyProfile = {
    //   companyName: { value: lineObj.company_name },
    //   primaryContact: {
    //     value: lineObj.contact_person,
    //   },
    //   emailId: { value: lineObj.user_email_id },
    //   fein: { value: lineObj.fein },
    //   phoneNumber: { value: lineObj.phone_number },
    //   primaryContact: {
    //     value: lineObj.contact_person,
    //   },
    //   effectiveDate: {
    //     value: lineObj.effective_date,
    //   },
    //   healthQues: {
    //     value:
    //       lineObj.healthQues === true ||
    //         lineObj.healthQues === false
    //         ? lineObj.healthQues
    //         : true,
    //   },
    // };
    //             let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
    //             let discountData = lineObj.discountData;

    //             if (discountData && discountData.value) {
    //               if (discountData.type === "percent") {
    //                 let valueToSub = numeral(adminFeeTemp)
    //                   .multiply(discountData.value)
    //                   .divide(100)
    //                   .value();
    //                 adminFeeTemp = numeral(adminFeeTemp)
    //                   .subtract(valueToSub)
    //                   .value();
    //               } else {
    //                 adminFeeTemp = discountData.value;
    //               }
    //             }

    //             let quoteFactors = {
    //               ficaCharge: lineObj.carrier_location_data.fica,
    //               futaCharge: lineObj.carrier_location_data.futa,
    //               sutaCharge: lineObj.carrier_location_data.suta,
    //               teap:
    //                 lineObj.carrier_location_data
    //                   .total_estimated_annual_premium,
    //               total: lineObj.carrier_location_data.total,
    //               adminFee: adminFeeTemp,
    //               payroll: 0,
    //             };

    //             let indicationCost = lineObj.carrier_location_data.total;

    //             sortKeyList.push(lineObj.uuid_carrier);
    //             quoteData.date = lineObj.date;
    //             quoteData.uuid = lineObj.uuid_carrier;
    //             address.push(lineObj.uuid_carrier.split("+")[1]);

    //             let amtrustAccountDetails = lineObj.amtrustAccountDetails;
    //             let amtrustAccountDetailsCopy =
    //               lineObj.amtrustAccountDetailsCopy;

    //             if (amtrustAccountDetails) {
    //               sessionStorage.setItem(
    //                 "amtrustAccountDetails",
    //                 JSON.stringify(amtrustAccountDetails)
    //               );
    //             }

    //             if (amtrustAccountDetailsCopy) {
    //               sessionStorage.setItem(
    //                 "amtrustAccountDetailsCopy",
    //                 JSON.stringify(amtrustAccountDetailsCopy)
    //               );
    //             }

    //             sessionStorage.setItem("preUwData", JSON.stringify(preUwData));
    //             sessionStorage.setItem(
    //               "currProspect",
    //               JSON.stringify(currProspectDetails)
    //             );
    //             if (currProspectDetails.childrenLoc)
    //               sessionStorage.setItem(
    //                 "childrenLoc",
    //                 JSON.stringify(currProspectDetails.childrenLoc)
    //               );
    //             sessionStorage.setItem(
    //               "sortKeyList",
    //               JSON.stringify(sortKeyList)
    //             );
    //             sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
    //             sessionStorage.setItem("address", JSON.stringify(address));
    //             sessionStorage.setItem("formStage", "three");
    //             sessionStorage.setItem("domain", domain);
    //             sessionStorage.setItem(
    //               "quoteFactors",
    //               JSON.stringify(quoteFactors)
    //             );
    //             requestBody = {
    //               email: user.toLowerCase(),
    //               domain: domain.toLowerCase(),
    //               timestamp: timestamp,
    //               payrollData: currProspectDetails.childrenLoc,
    //               companyProfile: currProspectDetails.companyProfile,
    //               indicationCost,
    //               emodStatesData: currProspectDetails.emodStatesData,
    //               campaignName,
    //               preventCompanyProfileUpdate: true,
    //             };

    //             if (user && !viewButton && !isHomeCampaign) {
    //               axios
    //                 .post(
    //                   awsUrl2 + "/api/updateSolveTrackingData",
    //                   JSON.stringify(requestBody)
    //                 )
    //                 .then((res) => {
    //                   axios
    //                     .post(
    //                       awsUrl2 +
    //                         "/api/updateEngagedWithEmailCampaign/" +
    //                         domain,
    //                       JSON.stringify(domain)
    //                     )
    //                     .finally(() => {
    //                       $("#loader").css("display", "none");
    //                       window.location.reload();
    //                     });
    //                 })
    //                 .catch((err) => {
    //                   $("#loader").css("display", "none");
    //                 });
    //             } else {
    //               window.location.reload();
    //               $("#loader").css("display", "none");
    //             }
    //           }
    //         }
    //       } else {
    //         if (currDomain !== domain) {
    //           axios
    //             .get(awsUrl2 + "/api/getDomainInputData/" + domain)
    //             .then(async (response) => {
    //               if (response.status === 200 && response.data.email_specific) {
    //                 sessionStorage.setItem("emailCampaignUser", true);
    //                 this.props.updateMailCampaignUser(true);

    //                 let stateCompanyProfile = JSON.parse(
    //                   JSON.stringify(this.state.companyProfile)
    //                 );

    //                 let {
    //                   companyProfile,
    //                   payrollData,
    //                   emodStatesData = {},
    //                   indicationCost,
    //                   email_specific,
    //                   client_source,
    //                   onlyPaygo,
    //                   fein,
    //                   campaignMap,
    //                   zywaveCompanyProfile,
    //                   source,
    //                   formStage,
    //                   amtrustAccountDetails,
    //                   amtrustAccountDetailsCopy,
    //                   uwAmtrustQuesList,
    //                   uwQuesList,
    //                   uwExpectedResponses,
    //                   preUwData,
    //                   preUwFiles,
    //                   dashboardFiles,
    //                   requestedPricing,
    //                   dealConfirmed,
    //                 } = response.data;

    //                 if (dealConfirmed === "true") {
    //                   sessionStorage.setItem("hideButtons", "true");
    //                 }

    //                 if (preUwFiles)
    //                   sessionStorage.setItem(
    //                     "preUwFiles",
    //                     JSON.stringify(preUwFiles)
    //                   );

    //                 if (dashboardFiles)
    //                   sessionStorage.setItem(
    //                     "dashboardFiles",
    //                     JSON.stringify(dashboardFiles)
    //                   );

    //                 if (amtrustAccountDetails) {
    //                   sessionStorage.setItem(
    //                     "amtrustAccountDetails",
    //                     JSON.stringify(amtrustAccountDetails)
    //                   );
    //                 }

    //                 if (amtrustAccountDetailsCopy) {
    //                   sessionStorage.setItem(
    //                     "amtrustAccountDetailsCopy",
    //                     JSON.stringify(amtrustAccountDetailsCopy)
    //                   );
    //                 }

    //                 let childrenLoc = payrollData;

    //                 for (let loc in childrenLoc) {
    //                   if (childrenLoc[loc].classCodesInfo) {
    //                     for (let cc in childrenLoc[loc].classCodesInfo) {
    //                       if (
    //                         !childrenLoc[loc].classCodesInfo[cc].ft ||
    //                         (childrenLoc[loc].classCodesInfo[cc].ft &&
    //                           !Number(
    //                             childrenLoc[loc].classCodesInfo[cc].ft.value
    //                           ))
    //                       ) {
    //                         childrenLoc[loc].classCodesInfo[cc] = {
    //                           ...childrenLoc[loc].classCodesInfo[cc],
    //                           ft: {
    //                             value:
    //                               zywaveCompanyProfile.employees &&
    //                               zywaveCompanyProfile.employees.value
    //                                 ? zywaveCompanyProfile.employees.value
    //                                 : 0,
    //                           },
    //                         };
    //                       }
    //                     }
    //                   }
    //                 }

    //                 await this.validateStateCityAndZip(childrenLoc);

    //                 let compName;

    //                 if (
    //                   companyProfile &&
    //                   companyProfile.company_name &&
    //                   companyProfile.company_name.value
    //                 ) {
    //                   compName = companyProfile.company_name.value;
    //                 }

    //                 if (companyProfile.state.value) {
    //                   companyProfile.state1 = {
    //                     value:
    //                       companyProfile.state1?.value ||
    //                       companyProfile.state.value,
    //                   };
    //                   companyProfile.state2 = {
    //                     value:
    //                       companyProfile.state2?.value ||
    //                       companyProfile.state.value,
    //                   };
    //                 }
    //                 if (companyProfile.zip.value) {
    //                   companyProfile.zipCode1 = {
    //                     value:
    //                       companyProfile.zipCode1?.value ||
    //                       companyProfile.zip.value,
    //                   };
    //                   companyProfile.zipCode2 = {
    //                     value:
    //                       companyProfile.zipCode2?.value ||
    //                       companyProfile.zip.value,
    //                   };
    //                 }
    //                 if (companyProfile.city.value) {
    //                   companyProfile.cityName1 = {
    //                     value:
    //                       companyProfile.cityName1?.value ||
    //                       companyProfile.city.value,
    //                   };
    //                   companyProfile.cityName2 = {
    //                     value:
    //                       companyProfile.cityName2?.value ||
    //                       companyProfile.city.value,
    //                   };
    //                 }
    //                 if (companyProfile.address.value) {
    //                   companyProfile.street1 = {
    //                     value:
    //                       companyProfile.street1?.value ||
    //                       companyProfile.address.value,
    //                   };
    //                   companyProfile.street2 = {
    //                     value:
    //                       companyProfile.street2?.value ||
    //                       companyProfile.address.value,
    //                   };
    //                 }

    //                 if (email_specific && !campaignMap) {
    //                   if (user in email_specific) {
    //                     companyProfile = {
    //                       ...companyProfile,
    //                       ...email_specific[user].companyProfile,
    //                     };
    //                   } else {
    //                     companyProfile.emailId.value = user;
    //                   }
    //                 }

    //                 if (user in email_specific) {
    //                   companyProfile = {
    //                     ...companyProfile,
    //                     contact_number:
    //                       email_specific[user].companyProfile.contact_number,
    //                     contact_name:
    //                       email_specific[user].companyProfile.contact_name,
    //                   };
    //                 }

    //                 if (compName) {
    //                   companyProfile = {
    //                     ...companyProfile,
    //                     companyName: {
    //                       value: compName,
    //                     },
    //                     company_name: {
    //                       value: compName,
    //                     },
    //                   };
    //                 }

    //                 companyProfile = {
    //                   ...stateCompanyProfile,
    //                   ...companyProfile,
    //                 };

    //                 if (
    //                   companyProfile &&
    //                   (!companyProfile.companyName ||
    //                     !companyProfile.companyName.value) &&
    //                   companyProfile.company_name &&
    //                   companyProfile.company_name.value
    //                 ) {
    //                   companyProfile.companyName = companyProfile.company_name;
    //                 }

    //                 if (
    //                   companyProfile &&
    //                   (!companyProfile.phoneNumber ||
    //                     !companyProfile.phoneNumber.value) &&
    //                   companyProfile.contact_number &&
    //                   companyProfile.contact_number.value
    //                 ) {
    //                   companyProfile.phoneNumber =
    //                     companyProfile.contact_number;
    //                 }

    //                 if (
    //                   companyProfile &&
    //                   companyProfile.contact_name &&
    //                   companyProfile.contact_name.value
    //                 ) {
    //                   let nameList = companyProfile.contact_name.value.split(
    //                     " "
    //                   );
    //                   let lastName = nameList.pop();
    //                   let firstName = nameList.join(" ");
    //                   if (lastName) {
    //                     companyProfile.lastName = {
    //                       value: lastName,
    //                     };
    //                   }
    //                   if (firstName) {
    //                     companyProfile.firstName = {
    //                       value: firstName,
    //                     };
    //                   }
    //                 }

    //                 if (
    //                   companyProfile &&
    //                   (!companyProfile.descriptionOfOperations ||
    //                     !companyProfile.descriptionOfOperations.value) &&
    //                   companyProfile.description_of_operations &&
    //                   companyProfile.description_of_operations.value
    //                 ) {
    //                   companyProfile.descriptionOfOperations =
    //                     companyProfile.description_of_operations;
    //                 }

    //                 if (
    //                   companyProfile &&
    //                   (!companyProfile.fein || !companyProfile.fein.value) &&
    //                   fein
    //                 ) {
    //                   companyProfile.fein = {
    //                     value: fein,
    //                   };
    //                 }

    //                 if (
    //                   companyProfile &&
    //                   companyProfile.fein &&
    //                   companyProfile.fein.value
    //                 ) {
    //                   let value = companyProfile.fein.value + "";
    //                   value = value.split("-").join("");
    //                   var len = value.length,
    //                     dummystr = "000000000";

    //                   if (len > 0 && len < 9) {
    //                     value = dummystr.slice(0, 9 - len) + "" + value;
    //                     companyProfile.fein.value =
    //                       value[0] + value[1] + "-" + value.slice(2);
    //                     companyProfile.fein.errMsg = "";
    //                   } else if (len > 9) {
    //                     companyProfile.fein.errMsg =
    //                       "FEIN needs to be 9 digits";
    //                     companyProfile.fein.error = true;
    //                   } else if (len === 0) {
    //                     companyProfile.fein.value = undefined;
    //                     companyProfile.fein.errMsg = "";
    //                   } else {
    //                     companyProfile.fein.value =
    //                       value[0] + value[1] + "-" + value.slice(2);
    //                     companyProfile.fein.errMsg = "";
    //                   }
    //                 }

    //                 if (
    //                   companyProfile &&
    //                   (!companyProfile.effectiveDate ||
    //                     (companyProfile.effectiveDate &&
    //                       !companyProfile.effectiveDate.value))
    //                 ) {
    //                   companyProfile = {
    //                     ...companyProfile,
    //                     effectiveDate: {
    //                       value:
    //                         moment().date() === 1
    //                           ? moment()
    //                           : moment(
    //                               moment().subtract(moment().date() - 1, "days")
    //                             ).add(1, "month"),
    //                     },
    //                   };
    //                 }

    //                 if (
    //                   companyProfile &&
    //                   (!companyProfile.effectiveDate ||
    //                     (companyProfile.effectiveDate &&
    //                       !companyProfile.effectiveDate.value))
    //                 ) {
    //                   companyProfile = {
    //                     ...companyProfile,
    //                     effectiveDate: {
    //                       value:
    //                         moment().date() === 1
    //                           ? moment()
    //                           : moment(
    //                               moment().subtract(moment().date() - 1, "days")
    //                             ).add(1, "month"),
    //                     },
    //                   };
    //                 }

    //                 let currProspect = {
    //                   childrenLoc,
    //                   companyProfile,
    //                   emodStatesData,
    //                   requestedPricing,
    //                 };

    //                 if (source) {
    //                   sessionStorage.setItem("source", source);
    //                 }

    //                 if (preUwData) {
    //                   sessionStorage.setItem(
    //                     "preUwData",
    //                     JSON.stringify(preUwData)
    //                   );
    //                 }

    //                 if (uwAmtrustQuesList) {
    //                   sessionStorage.setItem(
    //                     "uwAmtrustQuesList",
    //                     JSON.stringify(uwAmtrustQuesList)
    //                   );
    //                 }

    //                 if (uwQuesList) {
    //                   sessionStorage.setItem(
    //                     "uwQuesList",
    //                     JSON.stringify(uwQuesList)
    //                   );
    //                 }

    //                 if (uwExpectedResponses) {
    //                   currProspect.uwExpectedResponses = uwExpectedResponses;
    //                 }

    //                 if (!("healthQues" in companyProfile)) {
    //                   companyProfile.healthQues = { value: true };
    //                 }

    //                 sessionStorage.setItem(
    //                   "currProspect",
    //                   JSON.stringify(currProspect)
    //                 );
    //                 sessionStorage.setItem("formStage", formStage);
    //                 let sameAsPrimary = this.checkSameAsPrimary();
    //                 this.setState({ sameAsPrimary });

    //                 requestBody = {
    //                   email: user.toLowerCase(),
    //                   domain: domain.toLowerCase(),
    //                   timestamp: timestamp,
    //                   payrollData: childrenLoc,
    //                   companyProfile,
    //                   indicationCost,
    //                   emodStatesData,
    //                   campaignName,
    //                   emailSpecific: email_specific,
    //                   client_source,
    //                   onlyPaygo,
    //                   source,
    //                 };

    //                 if (user && !viewButton && !preUnderwriter) {
    //                   try {
    //                     if (!isHomeCampaign) {
    //                       await axios.post(
    //                         awsUrl2 + "/api/updateSolveTrackingData",
    //                         JSON.stringify(requestBody)
    //                       );

    //                       try {
    //                         await axios.post(
    //                           awsUrl2 +
    //                             "/api/updateEngagedWithEmailCampaign/" +
    //                             domain,
    //                           JSON.stringify(domain)
    //                         );
    //                       } catch (error) {}
    //                     }

    //                     try {
    //                       requestBody = {
    //                         domain,
    //                         formStage: !formStage ? "one" : formStage,
    //                         email: user,
    //                       };

    //                       await axios.post(
    //                         awsUrl2 +
    //                           "/api/updateSolveTrackingDataGeneric/updateFormStage",
    //                         JSON.stringify(requestBody)
    //                       );
    //                     } catch (error) {}

    //                     console.log("setting input data");
    //                     this.setInputData(currProspect);
    //                     if (formStage === "two") {
    //                       window.location.reload();
    //                     }
    //                   } catch (error) {}

    //                   $("#loader").css("display", "none");
    //                 } else {
    //                   window.location.reload();
    //                   $("#loader").css("display", "none");
    //                 }
    //               } else {
    //                 $("#loader").css("display", "none");
    //               }
    //             })
    //             .catch((err) => {
    //               console.log("error: ", err);
    //               $("#loader").css("display", "none");
    //             });
    //         } else {
    //           $("#loader").css("display", "none");
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       $("#loader").css("display", "none");
    //     });
    // } else if (sessionStorage.getItem("currProspect")) {
    //   let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));

    //   await this.validateStateCityAndZip(currProspect.childrenLoc);

    //   this.setInputData(currProspect);
    //   let sameAsPrimary = this.checkSameAsPrimary();
    //   this.setState({ sameAsPrimary });
    // } else {
    //   let { companyProfile } = this.state;

    //   companyProfile = {
    //     ...companyProfile,
    //     effectiveDate: { value: moment() },
    //     effectiveDate: { value: moment() },
    //   };

    //   this.setState({ companyProfile });

    //   $("#loader").css("display", "none");
    // }
  };
  // Auto fill the companyprofile field

  updateFormFields = (data) => {
    this.setState({ companyProfile: data.companyProfile });
    this.setState({ childrenLoc: data.childrenLoc });
    this.setState({ emod: data.emod });
    if (data?.includedExcludedObject?.includeExcludeList?.length > 0) {
      this.setState({ includedExcludedObject: data.includedExcludedObject });
    }
  };

  // handleChange = (e, key) => {
  //   let { historicalClaims } = this.state;
  //   let year = e.target.id.split("-")[1];

  //   if (!historicalClaims?.[year]) {
  //     historicalClaims[year] = {};
  //   }
  //   historicalClaims[year][key] = e.target.value;

  //   if (key === "totInc") {
  //     historicalClaims = histClaimCalcTI(historicalClaims, year);
  //   }
  //   if (key === "totPaid") {
  //     historicalClaims = histClaimCalcTP(historicalClaims, year);
  //   }
  //   if (key === "totRes") {
  //     historicalClaims = histClaimCalcTR(historicalClaims, year);
  //   }
  //   this.setState({ historicalClaims });
  // };

  // handleTotPaidBlur = (e) => {
  //   // zeroDefault(e);
  //   let { historicalClaims } = this.state;
  //   let year = e.target.id.split("-")[1];
  //   historicalClaims = histClaimCalcTP(historicalClaims, year);
  //   this.setState({ historicalClaims });
  // };

  // createTable = () => {
  //   let { companyProfile } = this.state;

  //   let table = [];
  //   let j = 0;
  //   let {
  //     historicalClaims,
  //     historicalClaimsCheck,
  //     historicalClaimsErrorStore,
  //     // submitClicked,
  //   } = this.state;

  //   historicalClaimsErrorStore = !historicalClaimsErrorStore
  //     ? {}
  //     : historicalClaimsErrorStore;
  //   historicalClaims = !historicalClaims ? {} : historicalClaims;
  //   historicalClaimsCheck = !historicalClaimsCheck ? {} : historicalClaimsCheck;

  //   let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
  //   let loss_data = currProspect ? currProspect.historicalClaims : null;
  //   let loss_data_index;
  //   let historyLimitYear = 0;
  //   let backgroundColor = "";
  //   let absYear;

  //   let rowId, rowIdDup, rowIdYear, rowIdDupYear;

  //   rowIdYear =
  //     new Date(companyProfile?.effectiveDate.value).getFullYear() ||
  //     new Date(
  //       currProspect?.companyProfile?.effectiveDate.value
  //     ).getFullYear() ||
  //     new Date().getFullYear();

  //   rowIdDupYear =
  //     new Date(companyProfile?.effectiveDate.value).getFullYear() ||
  //     new Date(
  //       currProspect?.companyProfile?.effectiveDate.value
  //     ).getFullYear() ||
  //     new Date().getFullYear();

  //   let currYear =
  //     new Date(companyProfile?.effectiveDate.value).getFullYear() ||
  //     new Date(
  //       currProspect?.companyProfile?.effectiveDate.value
  //     ).getFullYear() ||
  //     new Date().getFullYear();

  //   historyLimitYear =
  //     currYear - this.state?.companyProfile?.yearsInBusiness?.value ||
  //     currYear - currProspect?.companyProfile?.yearsInBusiness?.value;

  //   console.log("historyLimitYear:in ", historyLimitYear);

  //   for (let i = 0; i < 10; i++) {
  //     rowId = rowIdYear - j;
  //     rowIdDup = rowIdDupYear - i;
  //     absYear = !absYear ? rowIdDup : absYear;
  //     if (historicalClaimsCheck[absYear]) {
  //       backgroundColor = "#dddddd";
  //     } else {
  //       backgroundColor = "";
  //     }
  //     rowId = rowId.toString();
  //     if (i % 2 === 0) {
  //       j++;
  //       let yearId = Number(rowId);
  // loss_data_index = loss_data
  //   ? loss_data.findIndex((e) => e.year === rowId)
  //   : null;
  //       table.push(
  //         <tr id={rowIdDup} key={rowIdDup}>
  //           <td className="per30">
  //             <Form.Group>
  //               <label for={`carrier-${absYear}`}>Carrier</label>
  //               <input
  //                 autoComplete="off"
  //                 id={`carrier-${absYear}`}
  //                 type="text"
  //                 name="carText"
  //                 onChange={(e) => this.handleChange(e, "carrier")}
  //                 style={{
  //                   backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
  //                   backgroundColor: backgroundColor,
  //                   borderColor:
  //                     // submitClicked &&
  //                     historicalClaimsErrorStore[absYear] &&
  //                     historicalClaimsErrorStore[absYear].carrier
  //                       ? "red"
  //                       : "",
  //                 }}
  //                 disabled={historyLimitYear > rowId}
  //                 onKeyPress={(e) => {
  //                   this.clearErr(e, historicalClaimsErrorStore, "carrier");
  //                 }}
  //                 value={
  //                   historicalClaims &&
  //                   historicalClaims[absYear] &&
  //                   historicalClaims[absYear]["carrier"]
  //                     ? historicalClaims[absYear]["carrier"]
  //                     : ""
  //                 }
  //               />
  //             </Form.Group>
  //           </td>
  //           <td className="per10">
  //             <Form.Group>
  //               <label for={`annPre-${absYear}`} title="Annual Premium">
  //                 Annual Premium
  //               </label>
  //               <NumberFormat
  //                 name="anualPremium"
  //                 id={`annPre-${absYear}`}
  //                 autoComplete="off"
  //                 onKeyPress={(e) => {
  //                   this.clearErr(e, historicalClaimsErrorStore, "annPre");
  //                 }}
  //                 thousandSeparator={true}
  //                 onChange={(e) => this.handleChange(e, "annPre")}
  //                 style={{
  //                   backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
  //                   backgroundColor: backgroundColor,
  //                   borderColor:
  //                     // submitClicked &&
  //                     historicalClaimsErrorStore[absYear] &&
  //                     historicalClaimsErrorStore[absYear].annPre
  //                       ? "red"
  //                       : "",
  //                 }}
  //                 disabled={historyLimitYear > rowId}
  //                 prefix={"$"}
  //                 className="text-center"
  //                 type="text"
  //                 value={
  //                   historicalClaims &&
  //                   historicalClaims[absYear] &&
  //                   historicalClaims[absYear]["annPre"]
  //                     ? historicalClaims[absYear]["annPre"]
  //                     : ""
  //                 }
  //               />
  //             </Form.Group>
  //           </td>
  //           <td className="per5">
  //             <Form.Group>
  //               <label title="Number of claims" for={`claims-${absYear}`}>
  //                 # of Claims
  //               </label>
  //               <NumberFormat
  //                 name="claims"
  //                 id={`claims-${absYear}`}
  //                 maxLength="2"
  //                 autoComplete="off"
  //                 onKeyPress={(e) => {
  //                   this.clearErr(e, historicalClaimsErrorStore, "claim");
  //                 }}
  //                 onChange={(e) => this.handleChange(e, "claim")}
  //                 style={{
  //                   backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
  //                   backgroundColor: backgroundColor,
  //                   borderColor:
  //                     // submitClicked &&
  //                     historicalClaimsErrorStore[absYear] &&
  //                     historicalClaimsErrorStore[absYear].claim
  //                       ? "red"
  //                       : "",
  //                 }}
  //                 disabled={historyLimitYear > rowId}
  //                 className="text-center"
  //                 type="text"
  //                 value={
  //                   historicalClaims &&
  //                   historicalClaims[absYear] &&
  //                   historicalClaims[absYear]["claim"]
  //                     ? historicalClaims[absYear]["claim"]
  //                     : ""
  //                 }
  //               />
  //             </Form.Group>
  //           </td>
  //           <td className="per10">
  //             <Form.Group>
  //               <label for={`totInc-${absYear}`} title="Total Incurred">
  //                 Total Incurred
  //               </label>
  //               <NumberFormat
  //                 onFocus={(e) => {
  //                   this.clearErr(e, historicalClaimsErrorStore, "totInc");
  //                 }}
  //                 id={`totInc-${absYear}`}
  //                 autoComplete="off"
  //                 name="totInc"
  //                 thousandSeparator={true}
  //                 onChange={(e) => this.handleChange(e, "totInc")}
  //                 style={{
  //                   backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
  //                   backgroundColor: backgroundColor,
  //                   borderColor:
  //                     // submitClicked &&
  //                     historicalClaimsErrorStore[absYear] &&
  //                     historicalClaimsErrorStore[absYear].totInc
  //                       ? "red"
  //                       : "",
  //                 }}
  //                 disabled={historyLimitYear > rowId}
  //                 prefix={"$"}
  //                 className="text-center"
  //                 type="text"
  //                 // onBlur={(e) => this.zeroDefault(e, "totInc")}
  //                 value={
  //                   historicalClaims &&
  //                   historicalClaims[absYear] &&
  //                   historicalClaims[absYear]["totInc"]
  //                     ? historicalClaims[absYear]["totInc"]
  //                     : ""
  //                 }
  //               />
  //             </Form.Group>
  //           </td>
  //           <td className="per10">
  //             <Form.Group>
  //               <label for={`totPaid-${absYear}`} title="Total Paid">
  //                 Total Paid
  //               </label>
  //               <NumberFormat
  //                 onFocus={(e) => {
  //                   this.clearErr(e, historicalClaimsErrorStore, "totPaid");
  //                 }}
  //                 onBlur={this.handleTotPaidBlur}
  //                 id={`totPaid-${absYear}`}
  //                 autoComplete="off"
  //                 name="totPaid"
  //                 thousandSeparator={true}
  //                 onChange={(e) => this.handleChange(e, "totPaid")}
  //                 style={{
  //                   backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
  //                   backgroundColor: backgroundColor,
  //                   borderColor:
  //                     // submitClicked &&
  //                     historicalClaimsErrorStore[absYear] &&
  //                     historicalClaimsErrorStore[absYear].totPaid
  //                       ? "red"
  //                       : "",
  //                 }}
  //                 disabled={historyLimitYear > rowId}
  //                 prefix={"$"}
  //                 className="text-center"
  //                 type="text"
  //                 // onBlur={zeroDefault}
  //                 value={
  //                   historicalClaims &&
  //                   historicalClaims[absYear] &&
  //                   historicalClaims[absYear]["totPaid"]
  //                     ? historicalClaims[absYear]["totPaid"]
  //                     : ""
  //                 }
  //               />
  //             </Form.Group>
  //           </td>
  //           <td className="per10">
  //             <Form.Group>
  //               <label for={`totRes-${absYear}`} title="Total Reserves">
  //                 Total Reserves
  //               </label>
  //               <NumberFormat
  //                 onFocus={(e) => {
  //                   this.clearErr(e, historicalClaimsErrorStore, "totRes");
  //                 }}
  //                 id={`totRes-${absYear}`}
  //                 autoComplete="off"
  //                 name="totRes"
  //                 thousandSeparator={true}
  //                 onChange={(e) => this.handleChange(e, "totRes")}
  //                 style={{
  //                   backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
  //                   backgroundColor: backgroundColor,
  //                   borderColor:
  //                     // submitClicked &&
  //                     historicalClaimsErrorStore[absYear] &&
  //                     historicalClaimsErrorStore[absYear].totRes
  //                       ? "red"
  //                       : "",
  //                 }}
  //                 disabled={historyLimitYear > rowId}
  //                 prefix={"$"}
  //                 className="text-center "
  //                 type="text"
  //                 // onBlur={zeroDefault}
  //                 value={
  //                   historicalClaims &&
  //                   historicalClaims[absYear] &&
  //                   historicalClaims[absYear]["totRes"]
  //                     ? historicalClaims[absYear]["totRes"]
  //                     : ""
  //                 }
  //               />
  //             </Form.Group>
  //           </td>
  //         </tr>
  //       );
  //     } else {
  //       let yearId = Number(rowId) + 1;
  //       table.push(
  //         <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-auto">
  //           <td className="no-coverage">
  //             <input
  //               type="checkbox"
  //               id={`check-${absYear}`}
  //               disabled={historyLimitYear >= yearId}
  //               checked={
  //                 historyLimitYear > yearId ||
  //                 (historicalClaimsCheck[absYear]
  //                   ? historicalClaimsCheck[absYear]
  //                   : false)
  //               }
  //               onChange={(e) => this.handleCheck(e)}
  //             />
  //             No coverage for the year {yearId}
  //           </td>
  //           {/* <td className="per5 text-left">coverage for the {yearId}</td> */}
  //         </tr>
  //       );
  //       absYear--;
  //     }
  //   }
  //   return table;
  // };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  autoFillAcordData = (data, that) => {
    sessionStorage.setItem("acordData", JSON.stringify(data));
    const { basic_information, state_details, location_details } = data;
    if (
      basic_information.eff_date &&
      moment(basic_information.eff_date).isValid()
    ) {
      this.setState({ selDateEff: moment(basic_information.eff_date) });
    }
    if (
      basic_information.exp_date &&
      moment(basic_information.exp_date).isValid()
    ) {
      this.setState({ selDateExp: moment(basic_information.exp_date) });
    }
    if (basic_information.fein) {
      this.setState({ fein: basic_information.fein });
    }
    if (basic_information.company_name) {
      this.setState({ company_name: basic_information.company_name });
    }
    if (basic_information.phone_number) {
      this.setState({ phone_number: basic_information.phone_number });
    }

    if (basic_information.desc) {
      $("#description").val(basic_information.desc);
    }

    if (Object.entries(location_details).length > 0) {
      for (let locNumber in location_details) {
        that.onAddChildLoc(location_details[locNumber]);
      }
      that.onrmvChild(0);
    }
  };

  // uploadFile = (event) => {
  //   if (this.state.file) {
  //     var s3 = new AWS.S3(myConfig);
  //     // $("#loader").css("display", "block");
  //     this.setState({ uploadingFile: true });
  //     this.setState({
  //       error: false,
  //       urlSuccess: false,
  //       urlFail: false,
  //       basicInfoFail: false,
  //       stateInfoFail: false,
  //       signedUrl: "",
  //       awaitingFile: false,
  //     });

  //     console.log("Clicked", this.state.file[0]);
  //     const that = this;
  //     event.preventDefault();

  //     const formData = new FormData();
  //     formData.append("file", this.state.file[0]);

  //     axios
  //       .post(
  //         "https://5swuplkv50.execute-api.us-east-1.amazonaws.com/dev/api/fileUploadAcord",
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         console.log(response);

  //         that.setState({ uploadingFile: false });
  //         that.setState({ awaitingFile: true });
  //         const fileName = response.data.fileName;
  //         const folder = fileName.split(".")[0];
  //         const resultFilePath = `${folder}/finalResponse.json`;
  //         console.log("++succ", resultFilePath);
  //         var params = {
  //           Bucket: "ocr-doc-demo-acord",
  //           Key: resultFilePath,
  //           $waiter: {
  //             maxAttempts: 180,
  //             delay: 5,
  //           },
  //         };
  //         var getParams = {
  //           Bucket: "ocr-doc-demo-acord",
  //           Key: resultFilePath,
  //         };
  //         s3.waitFor("objectExists", params, function(err, data) {
  //           if (err) {
  //             console.log("file not yet ready", err);
  //             that.setState({ awaitingFile: false });
  //             that.setState({ error: true, errorMsg: "File not yet ready!" });
  //           } else {
  //             console.log("file created successfully", data); // successful response
  //             that.setState({ awaitingFile: false });
  //             s3.getSignedUrl("getObject", getParams, function(err, url) {
  //               if (err) {
  //                 $("#loader").css("display", "none");
  //                 console.log("Error", err);
  //                 that.setState({
  //                   error: true,
  //                   errorMsg: "Error in retreiving the file",
  //                 });
  //               } else {
  //                 console.log("The URL is", url);
  //                 fetch(url)
  //                   .then((response) => response.json())
  //                   .then((data) => {
  //                     console.log("++++data", data);
  //                     if (Object.entries(data).length === 0) {
  //                       that.setState({ urlFail: true });
  //                     } else if (
  //                       Object.entries(data.basic_information).length === 0 &&
  //                       Object.entries(data.location_details).length !== 0
  //                     ) {
  //                       that.setState({ basicInfoFail: true });
  //                       that.autoFillAcordData(data, that);
  //                     } else if (
  //                       Object.entries(data.basic_information).length !== 0 &&
  //                       Object.entries(data.location_details).length === 0
  //                     ) {
  //                       that.setState({ stateInfoFail: true });
  //                       that.autoFillAcordData(data, that);
  //                     } else if (
  //                       Object.entries(data.basic_information).length === 0 &&
  //                       Object.entries(data.location_details).length === 0
  //                     ) {
  //                       that.setState({ urlFail: true });
  //                     } else {
  //                       that.setState({ urlSuccess: true, signedUrl: url });
  //                       that.autoFillAcordData(data, that);
  //                     }
  //                     $("#loader").css("display", "none");
  //                   })
  //                   .catch((error) => {
  //                     console.log(`Failed because: ${error}`);
  //                     $("#loader").css("display", "none");
  //                   });
  //               }
  //             });
  //           }
  //         });
  //       })
  //       .catch((error) => {
  //         $("#loader").css("display", "none");
  //         this.setState({ uploadingFile: false });
  //         console.log("++error", error);
  //         // handle your error
  //       });
  //   }
  // };

  // historicalonBlurHandler = (event) => {
  //   let { companyProfile, historicalClaims } = this.state;
  //   let historicalClaimsCheck = {};

  //   let currYear =
  //     new Date(companyProfile.effectiveDate.value).getFullYear() ||
  //     new Date().getFullYear();
  //   console.log("currYear: ", currYear);

  //   let historyLimitYear = currYear - event.target.value;
  //   console.log("historyLimitYear: ", historyLimitYear);
  //   for (let i = 0; i < 5; i++) {
  //     if (currYear >= historyLimitYear) {
  //       historicalClaimsCheck[currYear] = false;
  //     } else {
  //       historicalClaimsCheck[currYear] = true;
  //     }
  //     currYear--;
  //   }

  //   this.setHistoricalClaims();

  //   this.setState({ companyProfile, historicalClaimsCheck, historicalClaims });
  // };

  //same as primarycheck
  sameAsPrimaryCheckHandler = (event, feild, checkFeild) => {
    let { sameAsPrimary, companyProfile } = this.state;
    const resetFields = () => {
      companyProfile.cityName1 = { value: "" };
      companyProfile.zipCode1 = { value: "" };
      companyProfile.street1 = { value: "" };
      companyProfile.cityName2 = { value: "" };
      companyProfile.zipCode2 = { value: "" };
      companyProfile.street2 = { value: "" };
    };

    if (sameAsPrimary) {
      if (feild == "state2") {
        if (
          companyProfile?.[feild]?.value !== companyProfile?.[checkFeild]?.value
        ) {
          resetFields();
          this.setState({ sameAsPrimary: false, companyProfile });
        }
      } else {
        if (feild == "zipCode2" && event.target.value === "") {
          companyProfile.state2 = { value: "" };
          companyProfile.cityName2 = { value: "" };
          companyProfile.cityName1 = { value: "" };
          companyProfile.zipCode1 = { value: "" };
          companyProfile.street1 = { value: "" };
          this.setState({ sameAsPrimary: false, companyProfile });
        } else {
          if (
            companyProfile?.[feild]?.value !==
            companyProfile?.[checkFeild]?.value
          ) {
            companyProfile.cityName1 = { value: "" };
            companyProfile.zipCode1 = { value: "" };
            companyProfile.street1 = { value: "" };
            this.setState({ sameAsPrimary: false, companyProfile });
          }
        }
      }
    } else {
      if (feild == "state2") {
        if (
          companyProfile?.[feild]?.value !== companyProfile?.[checkFeild]?.value
        ) {
          resetFields();
          this.setState({ sameAsPrimary: false, companyProfile });
        }
      } else if (feild == "zipCode2" && event.target.value === "") {
        companyProfile.state2 = { value: "" };
        companyProfile.cityName2 = { value: "" };
        this.setState({ sameAsPrimary: false, companyProfile });
      }
    }
  };

  updateCompanyProfile = (event, fieldName) => {
    let { companyProfile } = this.state;
    let value = event?.target?.value;
    value = value?.trimStart();
    value = value?.replace(/\s+/g, " ");
    if (fieldName === "state1" || fieldName === "state2") {
      companyProfile[fieldName] = { value: value.toLowerCase() };
    } else {
      companyProfile[fieldName] = { value: value };
    }
    this.setState({ companyProfile });
  };

  feinAgencyMapCheck = async (value) => {
    let feinAgencyMap = JSON.parse(sessionStorage.getItem("feinAgencyMap"));
    let isLoggedIn;
    let agency_code;
    try {
      isLoggedIn = await Auth.currentAuthenticatedUser();
      agency_code = isLoggedIn?.attributes["custom:agency_code"]
        ? isLoggedIn?.attributes["custom:agency_code"]
        : "";
    } catch (error) {
      console.log("Authentication error here", error);
    }
    console.log("Suraj 9879",feinAgencyMap[agency_code])
    if (feinAgencyMap[agency_code].includes(value)) {
      return true
    } else {
      return false
    }
  };

  handleKeyDown = (event) => {
    // Prevent the input of numeric characters
    if (event.key >= "0" && event.key <= "9") {
      event.preventDefault();
    }
  };

  checkEligibleForClasscodeHandler = async (event, index) => {
    let { includedExcludedObject } = this.state;
    let { value } = event.target;
    // if it is not included and it is exluded and if it is not doesn't have any classcode then it self we will check for eligibility.
    if (
      includedExcludedObject["includeExcludeList"][index]["includExclude"][
        "value"
      ] === "Excluded" ||
      includedExcludedObject["includeExcludeList"][index]["includExclude"][
        "value"
      ] == ""
    ) {
      value = value
        .toString()
        .trim()
        .padStart(4, "0");
      let requestBody = {
        classCode: value,
        state: "fl",
        carrier: "carrier_ax",
      };
      // call api to check this callcode is present in E  ligibity table or not.
      if (value.length > 0) {
        let res = await axios.post(
          awsUrl2 + `/api/checkEligibilityIncExcCC`,
          requestBody
        );
        if (!res.data.eligibilitycheck) {
          if (includedExcludedObject["includeExcludeEligibilityCheck"]) {
            includedExcludedObject["includeExcludeEligibilityCheck"] = {};
          }
          includedExcludedObject["includeExcludeEligibilityCheck"][
            index
          ] = true;
          this.setState({ includedExcludedObject });
        }
      }
    }
  };
  checkEligibleForClasscodeHandler = async (event, index) => {
    let { includedExcludedObject } = this.state;
    let { value } = event.target;

    // Check conditions for eligibility check
    if (
      includedExcludedObject["includeExcludeList"][index]["includExclude"][
        "value"
      ] === "Excluded" ||
      includedExcludedObject["includeExcludeList"][index]["includExclude"][
        "value"
      ] == "" ||
      includedExcludedObject["includeExcludeList"][index]["includExclude"][
        "value"
      ] === "Please Select"
    ) {
      value = value.toString().trim();

      let requestBody = {
        classCode: value,
        state: "fl",
        carrier: "carrier_ax",
      };

      // Call API to check eligibility for the class code
      if (value.length > 0) {
        try {
          let res = await axios.post(
            awsUrl2 + `/api/checkEligibilityIncExcCC`,
            requestBody
          );

          if (!res.data.eligibilitycheck) {
            if (!includedExcludedObject["includeExcludeEligibilityCheck"]) {
              includedExcludedObject["includeExcludeEligibilityCheck"] = {};
            }
            includedExcludedObject["includeExcludeEligibilityCheck"][
              index
            ] = true;
            this.setState({ includedExcludedObject });
          } else {
            delete includedExcludedObject["includeExcludeEligibilityCheck"][
              index
            ];
            this.setState({ includedExcludedObject });
          }
        } catch (error) {
          console.error("Error occurred while checking eligibility:", error);
          // Handle error appropriately
        }
      }
    }
  };

  updateLocationInfo = (value, locationIndex, fieldName, classCodeIndex) => {
    let { childrenLoc, emodStatesData, state_class_code } = this.state;
    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex] = {};
      this.setState({ childrenLoc });
    } else {
      let prevState;
      if (
        fieldName === "state" &&
        childrenLoc[locationIndex][fieldName].value
      ) {
        prevState = childrenLoc[locationIndex][fieldName].value;

        //cc_delete
        for (let classCodeIndex in childrenLoc[locationIndex].classCodesInfo) {
          let currState = childrenLoc[locationIndex].state.value || "";
          try {
            if (
              currState.toLowerCase() in state_class_code &&
              state_class_code[currState.toLowerCase()].val &&
              childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                "classCodeDescription"
              ]
            ) {
              let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
                classCodeIndex
              ]["classCodeDescription"]
                ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                    "classCodeDescription"
                  ].value
                : undefined;
              if (class_code_desc) {
                let class_code = class_code_desc.split(":")[0].trim();
                if (
                  state_class_code[currState.toLowerCase()].cc_dsc &&
                  class_code in state_class_code[currState.toLowerCase()].cc_dsc
                ) {
                  let locToUpdate, ccToUpdate;
                  for (let locIndx in childrenLoc) {
                    if (childrenLoc[locIndx].state.value === currState) {
                      let ccInfo = childrenLoc[locIndx].classCodesInfo;
                      for (let ccIndx in ccInfo) {
                        let cc_desc;
                        try {
                          cc_desc =
                            ccInfo[ccIndx]["classCodeDescription"].value;
                        } catch (e) {}
                        if (cc_desc) {
                          let cc = cc_desc.split(":")[0].trim();
                          if (Number(cc) === Number(class_code)) {
                            childrenLoc[locIndx].classCodesInfo[ccIndx][
                              "pricing"
                            ] = {
                              value:
                                state_class_code[currState.toLowerCase()]
                                  .cc_dsc[class_code],
                              disabled: true,
                            };
                            if (
                              !locToUpdate &&
                              !ccToUpdate &&
                              Number(locIndx) !== Number(locationIndex)
                            ) {
                              locToUpdate = locIndx;
                              ccToUpdate = ccIndx;
                            }
                          }
                        }
                      }
                    }
                  }
                  if (locToUpdate && ccToUpdate) {
                    childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                      "pricing"
                    ].disabled = false;
                    if (
                      !("cc_dsc" in state_class_code[currState.toLowerCase()])
                    ) {
                      state_class_code[currState.toLowerCase()].cc_dsc = {};
                    }
                    try {
                      state_class_code[currState.toLowerCase()].cc_dsc[
                        class_code
                      ] =
                        childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                          "pricing"
                        ].value;
                    } catch (error) {}
                  } else {
                    delete state_class_code[currState.toLowerCase()].cc_dsc[
                      class_code
                    ];
                  }
                }
              }
            }
          } catch (error) {}
        }
        //cc_delete
      }
      childrenLoc[locationIndex][fieldName] = { value: value };
      if (fieldName === "suta") {
        try {
          if (
            childrenLoc[locationIndex].state &&
            childrenLoc[locationIndex].state.value &&
            childrenLoc[locationIndex].state.value.toLowerCase() in
              state_class_code
          ) {
            state_class_code[
              childrenLoc[locationIndex].state.value.toLowerCase()
            ] = {
              ...state_class_code[
                childrenLoc[locationIndex].state.value.toLowerCase()
              ],
              val: value,
            };
            for (let addressBlockNo in childrenLoc) {
              if (
                childrenLoc[addressBlockNo].state.value &&
                childrenLoc[addressBlockNo].state.value ===
                  childrenLoc[locationIndex].state.value &&
                childrenLoc[addressBlockNo].suta.value !== value
              ) {
                childrenLoc[addressBlockNo].suta.value = value;
                childrenLoc[addressBlockNo].suta.disabled = true;
              }
            }
          }
        } catch (error) {}
      }
      if (fieldName === "clientsuta") {
        try {
          if (
            childrenLoc[locationIndex].state &&
            childrenLoc[locationIndex].state.value &&
            childrenLoc[locationIndex].state.value.toLowerCase() in
              state_class_code
          ) {
            state_class_code[
              childrenLoc[locationIndex].state.value.toLowerCase()
            ] = {
              ...state_class_code[
                childrenLoc[locationIndex].state.value.toLowerCase()
              ],
              val2: value,
            };
            for (let addressBlockNo in childrenLoc) {
              if (
                childrenLoc[addressBlockNo].state.value &&
                childrenLoc[addressBlockNo].state.value ===
                  childrenLoc[locationIndex].state.value &&
                childrenLoc[addressBlockNo].clientsuta.value !== value
              ) {
                childrenLoc[addressBlockNo].clientsuta.value = value;
                childrenLoc[addressBlockNo].clientsuta.disabled = true;
              }
            }
          }
        } catch (error) {}
      }
      if (fieldName === "state") {
        let stateList = [];

        try {
          for (let addressBlockNo in childrenLoc) {
            if (childrenLoc[addressBlockNo].state.value) {
              if (
                prevState &&
                prevState === childrenLoc[addressBlockNo].state.value
              ) {
                childrenLoc[addressBlockNo].suta.disabled = false;
                childrenLoc[addressBlockNo].clientsuta.disabled = false;
                prevState = "#####";
              }
              stateList.push(
                childrenLoc[addressBlockNo].state.value.toLowerCase()
              );
            }
          }

          if (prevState && prevState !== "#####") {
            delete state_class_code[prevState.toLowerCase()];
          }
        } catch (error) {}

        let stateSet = new Set(stateList);

        try {
          for (let stateIndx in state_class_code) {
            if (!stateSet.has(stateIndx.toLowerCase())) {
              delete state_class_code[stateIndx];
            }
          }

          if (!(value.toLowerCase() in state_class_code)) {
            state_class_code[value.toLowerCase()] = {
              ...state_class_code[value.toLowerCase()],
              val: childrenLoc[locationIndex].suta.value
                ? childrenLoc[locationIndex].suta.value
                : "",
              val2: childrenLoc[locationIndex].clientsuta.value
                ? childrenLoc[locationIndex].clientsuta.value
                : "",
            };
            childrenLoc[locationIndex].suta.disabled = false;
            childrenLoc[locationIndex].clientsuta.disabled = false;
          } else {
            if (state_class_code[value.toLowerCase()].val) {
              childrenLoc[locationIndex]["suta"] = {
                value: state_class_code[value.toLowerCase()].val,
                disabled: true,
              };
            } else {
              childrenLoc[locationIndex]["suta"] = {
                value: "",
                disabled: false,
              };
            }
            if (state_class_code[value.toLowerCase()].val2) {
              childrenLoc[locationIndex]["clientsuta"] = {
                value: state_class_code[value.toLowerCase()].val2,
                disabled: true,
              };
            } else {
              childrenLoc[locationIndex]["clientsuta"] = {
                value: "",
                disabled: false,
              };
            }
          }
        } catch (error) {}

        if (stateList.length > 0) {
          let statesData = {
            ncciStates: [],
            actingNcciStates: [],
            indBureauStates: [],
          };
          axios
            .post(
              awsUrl2 + "/api/getExpModStateCategory",
              JSON.stringify({ states: stateList })
            )
            .then((response) => {
              statesData = response.data;
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              // let newEmodData = getEmodStatesData(
              //   statesData,
              //   emodStatesData || {}
              // );
              this.setState({
                childrenLoc,
                // emodStatesData: newEmodData,
                state_class_code,
              });
            });
        } else {
          emodStatesData = {};
          this.setState({ childrenLoc, emodStatesData, state_class_code });
        }
      } else {
        this.setState({ childrenLoc, state_class_code });
      }
    }
  };

  removeClassCode = (locationIndex, classCodeIndex) => {
    let { childrenLoc, state_class_code } = this.state;

    if (
      !childrenLoc[locationIndex].state ||
      !childrenLoc[locationIndex].state.value
    ) {
      delete childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
      this.setState({ childrenLoc });
      return;
    }

    //code to handle state-classcode combination requested pricing starts
    let currState = childrenLoc[locationIndex].state.value || "";
    try {
      if (
        currState.toLowerCase() in state_class_code &&
        state_class_code[currState.toLowerCase()].val
      ) {
        let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
          classCodeIndex
        ]["classCodeDescription"]
          ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value
          : undefined;
        if (class_code_desc) {
          let class_code = class_code_desc.split(":")[0].trim();
          if (
            state_class_code[currState.toLowerCase()].cc_dsc &&
            class_code in state_class_code[currState.toLowerCase()].cc_dsc
          ) {
            let locToUpdate, ccToUpdate;
            for (let locIndx in childrenLoc) {
              if (childrenLoc[locIndx].state.value === currState) {
                let ccInfo = childrenLoc[locIndx].classCodesInfo;
                for (let ccIndx in ccInfo) {
                  if (
                    !(
                      ccInfo[ccIndx] &&
                      ccInfo[ccIndx].classCodeDescription &&
                      ccInfo[ccIndx].classCodeDescription.value
                    )
                  ) {
                    continue;
                  }
                  let local_cc_desc = ccInfo[ccIndx].classCodeDescription.value;

                  let local_cc = local_cc_desc.split(":")[0].trim();
                  if (
                    !locToUpdate &&
                    !ccToUpdate &&
                    (Number(locIndx) !== Number(locationIndex) ||
                      Number(ccIndx) !== Number(classCodeIndex)) &&
                    Number(class_code) === Number(local_cc)
                  ) {
                    childrenLoc[locIndx].classCodesInfo[
                      ccIndx
                    ].pricing.disabled = true;
                    locToUpdate = locIndx;
                    ccToUpdate = ccIndx;
                  }
                }
              }
            }
            if (locToUpdate && ccToUpdate) {
              childrenLoc[locToUpdate].classCodesInfo[
                ccToUpdate
              ].pricing.disabled = false;
            } else {
              delete state_class_code[currState.toLowerCase()].cc_dsc[
                class_code
              ];
            }
          }
        }
      }
    } catch (error) {}
    //code to handle state-classcode ends here

    delete childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
    this.setState({ childrenLoc, state_class_code });
  };

  updateClassCodesInfo = (locationIndex, classCodeIndex, fieldName, value) => {
    let { childrenLoc, state_class_code } = this.state;

    childrenLoc[locationIndex].classCodesInfo[classCodeIndex][fieldName] = {
      value: value,
    };

    if (fieldName === "pricing") {
      let currState = childrenLoc[locationIndex].state.value || "";
      if (currState.toLowerCase() in state_class_code) {
        let class_code_desc;
        try {
          class_code_desc =
            childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value;
        } catch (error) {}
        if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
          state_class_code[currState.toLowerCase()].cc_dsc = {};
        }
        try {
          if (class_code_desc) {
            let class_code = class_code_desc.split(":")[0].trim();
            if (
              !(class_code in state_class_code[currState.toLowerCase()].cc_dsc)
            ) {
              state_class_code[currState.toLowerCase()].cc_dsc[
                class_code
              ] = value;
            } else {
              let locToUpdate, ccToUpdate;
              for (let locIndx in childrenLoc) {
                if (childrenLoc[locIndx].state.value === currState) {
                  let ccInfo = childrenLoc[locIndx].classCodesInfo;
                  for (let ccIndx in ccInfo) {
                    let cc_desc;
                    try {
                      cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                    } catch (error) {}
                    if (cc_desc) {
                      let cc = cc_desc.split(":")[0].trim();
                      if (cc === class_code) {
                        childrenLoc[locIndx].classCodesInfo[ccIndx][
                          "pricing"
                        ] = {
                          value: value,
                          disabled: true,
                        };
                        if (!locToUpdate && !ccToUpdate) {
                          locToUpdate = locIndx;
                          ccToUpdate = ccIndx;
                        }
                      }
                    }
                  }
                }
              }
              if (locToUpdate && ccToUpdate) {
                childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                  "pricing"
                ].disabled = false;

                state_class_code[currState.toLowerCase()].cc_dsc[
                  class_code
                ] = value;
              }
            }
          }
        } catch (error) {}
      }
    }
    try {
      if (fieldName === "classCodeDescription") {
        let currState = childrenLoc[locationIndex].state.value || "";
        if (currState.toLowerCase() in state_class_code) {
          let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
            classCodeIndex
          ]["classCodeDescription"]
            ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                "classCodeDescription"
              ].value
            : undefined;
          if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
            state_class_code[currState.toLowerCase()].cc_dsc = {};
          }
          if (class_code_desc) {
            let class_code = class_code_desc.split(":")[0].trim();
            if (
              class_code in state_class_code[currState.toLowerCase()].cc_dsc
            ) {
              let locToUpdate, ccToUpdate;
              for (let locIndx in childrenLoc) {
                if (childrenLoc[locIndx].state.value === currState) {
                  let ccInfo = childrenLoc[locIndx].classCodesInfo;
                  for (let ccIndx in ccInfo) {
                    let cc_desc;
                    try {
                      cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                    } catch (e) {}
                    if (cc_desc) {
                      let cc = cc_desc.split(":")[0].trim();
                      if (cc === class_code) {
                        childrenLoc[locIndx].classCodesInfo[ccIndx][
                          "pricing"
                        ] = {
                          value:
                            state_class_code[currState.toLowerCase()].cc_dsc[
                              class_code
                            ],
                          disabled: true,
                        };
                        if (!locToUpdate && !ccToUpdate) {
                          locToUpdate = locIndx;
                          ccToUpdate = ccIndx;
                        }
                      }
                    }
                  }
                }
              }
              if (locToUpdate && ccToUpdate) {
                childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                  "pricing"
                ].disabled = false;
              }
            } else {
              if (
                childrenLoc[locationIndex].classCodesInfo[classCodeIndex]
                  .pricing &&
                childrenLoc[locationIndex].classCodesInfo[classCodeIndex]
                  .pricing.value
              ) {
                state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                  childrenLoc[locationIndex].classCodesInfo[
                    classCodeIndex
                  ].pricing.value;
              }
            }
          }
        }
      }
    } catch (error) {}
    this.setState({ childrenLoc, state_class_code });
  };

  updateLocationError = (locationIndex, classCodeIndex, fieldName, value) => {
    let { childrenLoc } = this.state;
    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
        fieldName
      ].error = value;
    } else {
      childrenLoc[locationIndex][fieldName].error = value;
    }
    this.setState({ childrenLoc });
  };

  resetClassCodeDescriptionData = (locationIndex) => {
    let { childrenLoc } = this.state;
    for (let index in childrenLoc[locationIndex].classCodesInfo) {
      if (
        childrenLoc[locationIndex].classCodesInfo[index].classCodeDescription
      ) {
        delete childrenLoc[locationIndex].classCodesInfo[index]
          .classCodeDescription.value;
      }
      if (childrenLoc[locationIndex].classCodesInfo[index].pricing) {
        delete childrenLoc[locationIndex].classCodesInfo[index].pricing;
      }
    }
    this.setState({ childrenLoc });
  };

  checkCompanyProfileError = async () => {
    let { companyProfile } = this.state;

    let fieldList = ["companyName"];

    // fieldList.push("firstName");
    // fieldList.push("lastName");
    // fieldList.push("phoneNumber");
    fieldList.push("descriptionOfOperations");
    fieldList.push("entityType");
    fieldList.push("effectiveDate");
    fieldList.push("fein");
    fieldList.push("yearsInBusiness");
    fieldList.push("empLiabilityLimit");
    fieldList.push("street1");
    fieldList.push("zipCode1");
    fieldList.push("cityName1");
    fieldList.push("state1");
    fieldList.push("zipCode2");
    fieldList.push("street2");
    fieldList.push("cityName2");
    fieldList.push("state2");

    let error = false;
    let field;
    for (let fieldName of fieldList) {
      try {
        if (
          !companyProfile[fieldName] ||
          !companyProfile[fieldName]?.value ||
          companyProfile[fieldName]?.error
        ) {
          companyProfile[fieldName].error = true;
          if (!field) field = fieldName;
          error = true;
        }
        if (fieldName === "zipCode2" || fieldName === "zipCode1") {
          let value = fieldName;
          if (companyProfile?.[value]?.value.trim().length < 5) {
            companyProfile[value].error = true;
            if (!field) field = value;
            error = true;
          }
        }

        if(fieldName==="fein"){
          let feinerror=await this.feinAgencyMapCheck(companyProfile[fieldName]?.value)
          console.log("Suraj",feinerror)
          if(feinerror){
            companyProfile.fein.error = true;
          }else{
            companyProfile.fein.error = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    this.setState({ companyProfile });
    return [error, field];
  };

  validateHistoricalClaims = (historicalClaims, historicalClaimsCheck) => {
    let { historicalClaimsError } = this.state;

    let checkMap = {
      falseOne: false,
      trueMid: false,
      falseTwo: false,
    };

    let validHistoricalClaims;

    for (let year in historicalClaimsCheck) {
      if (historicalClaimsCheck[year] === false) {
        if (!checkMap.falseOne) {
          checkMap.falseOne = true;
        } else if (checkMap.trueMid) {
          checkMap.falseTwo = true;
        }
      } else {
        if (checkMap.falseOne) {
          checkMap.trueMid = true;
        }
      }
    }
    let tempFlag = !(
      checkMap.falseOne &&
      checkMap.trueMid &&
      checkMap.falseTwo
    );
    let consecutiveYearErrorFlag = !tempFlag;

    let historicalClaimsErrorStore = {};
    let keys = ["carrier", "claim", "annPre", "totInc", "totPaid", "totRes"];
    let emptyFieldFlag = false;
    for (let year in historicalClaimsCheck) {
      if (!historicalClaimsErrorStore[year]) {
        historicalClaimsErrorStore[year] = {};
      }
      for (let key of keys) {
        if (
          historicalClaims?.[year] &&
          historicalClaims?.[year]?.[key] &&
          historicalClaims?.[year]?.[key].trim() !== ""
        ) {
          historicalClaimsErrorStore[year][key] = false;
        } else {
          if (!historicalClaimsCheck[year]) {
            historicalClaimsErrorStore[year][key] = true;
            emptyFieldFlag = true;
          }
        }
      }
    }

    validHistoricalClaims = !consecutiveYearErrorFlag && !emptyFieldFlag;

    this.setState({
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsError,
      historicalClaimsErrorStore,
    });

    return {
      validHistoricalClaims,
      historicalClaimsError: consecutiveYearErrorFlag,
      historicalClaimsErrorStore,
    };
  };
  checkLocationInfoError = () => {
    let { childrenLoc } = this.state;
    let error = false;

    let classCodeKeys = ["classCodeDescription", "ft", "payroll"];

    let field;
    let index = 0;
    for (let addressBlockNo in childrenLoc) {
      for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
        classCodeKeys.forEach((fieldName) => {
          if (
            !(
              fieldName in
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
            )
          ) {
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ] = { error: true };
            error = true;
            if (!field) field = "officeLoc" + index;
          } else if (
            !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].value ||
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error
          ) {
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error = true;
            if (!field) field = "officeLoc" + index;
            error = true;
          }
          if (
            childrenLoc?.[addressBlockNo]?.classCodesInfo?.[addressChildNo]?.[
              "ft"
            ]?.value == 0
          ) {
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              "ft"
            ].error = true;
            error = true;
          }
        });
      }
      index++;
    }
    this.setState({ childrenLoc });
    return [error, field];
  };
  // validate the include and exclude fields
  validateIncludeExcludedHandler = () => {
    let { includedExcludedObject, childrenLoc } = this.state;
    let includeExcludeError = false;

    // collecting the class code for check of include and matching the classcode

    let classCodeToInlcude = [];

    for (let keyValue in childrenLoc[0]["classCodesInfo"]) {
      let includeClasscodeCheck = childrenLoc[0]["classCodesInfo"][keyValue][
        "classCodeDescription"
      ]["value"].split(":")[0];

      classCodeToInlcude.push(
        includeClasscodeCheck.toString().padStart(4, "0")
      );
    }
    console.log(classCodeToInlcude, "classCodeToInlcude");

    // validating for null part or empty part
    for (const [
      index,
      key,
    ] of includedExcludedObject.includeExcludeList.entries()) {
      if (key["officerName"]["value"] === "") {
        key["officerName"]["error"] = true;
        includeExcludeError = true;
      } else {
        key["officerName"]["error"] = false;
      }
      if (key["ownerShip"]["value"] === "") {
        key["ownerShip"]["error"] = true;
        includeExcludeError = true;
      } else {
        key["ownerShip"]["error"] = false;
      }
      if (
        key["includExclude"]["value"] === "" ||
        key["includExclude"]["value"] === "Please Select"
      ) {
        key["includExclude"]["error"] = true;
        includeExcludeError = true;
      } else {
        key["includExclude"]["error"] = false;
      }
      if (key["classCode"]["value"] === "") {
        key["classCode"]["error"] = true;
        includeExcludeError = true;
      } else {
        key["classCode"]["error"] = false;
      }

      if (
        Object.keys(
          includedExcludedObject?.["includeExcludeEligibilityCheck"] || {}
        ).length > 0
      ) {
        includeExcludeError = true;
      }

      // Your validation and processing logic here
      if (key?.["includExclude"]?.["value"] === "Included") {
        let classCode = key?.["classCode"]?.["value"]
          .trim()
          .toString()
          .padStart("0", 4);

        console.log("classCodeToInlcude", classCodeToInlcude);

        if (!classCodeToInlcude.includes(classCode)) {
          key["classCode"]["error"] = true;
          includeExcludeError = true;
          if (!includedExcludedObject["includeCodeCodeError"]) {
            includedExcludedObject["includeCodeCodeError"] = {};
          }
          includedExcludedObject["includeCodeCodeError"][index] = true;
        } else {
          if (!includedExcludedObject["includeCodeCodeError"]) {
            includedExcludedObject["includeCodeCodeError"] = {};
          }
          includedExcludedObject["includeCodeCodeError"][index] = false;
        }
      }
      if (key?.["includExclude"]?.["value"] === "Excluded") {
        if (!includedExcludedObject["includeCodeCodeError"]) {
          includedExcludedObject["includeCodeCodeError"] = {};
        }
        includedExcludedObject["includeCodeCodeError"][index] = false;
        // key["classCode"]["error"] = false;
      }
    }

    this.setState({ includedExcludedObject });
    return { includeExcludeError };
  };
  // validate the additional Entity

  ValidatAddintionalEntityHandler = () => {
    let { additionalEntity } = this.state;
    let additionalEntityError = false;
    for (let key of additionalEntity.entityList) {
      if (key["companyName"]["value"] === "") {
        key["companyName"]["error"] = true;
        additionalEntityError = true;
      } else {
        key["companyName"]["error"] = false;
      }
      if (key["fein"]["value"] === "") {
        key["fein"]["error"] = true;
        additionalEntityError = true;
      } else {
        key["fein"]["error"] = false;
      }
    }
    this.setState({ additionalEntity });
    return { additionalEntityError };
  };

  validEmail = (e) => {
    let { companyProfile, otpVerified } = this.state;
    var email = e.target.value;
    var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = reg.test(email);
    if (!isValid && email !== "") {
      companyProfile.emailId.error = true;
    } else {
      if (!otpVerified) {
        axios
          .post(awsUrl2 + "/api/sendOtpMail", { email })
          .then((res) => {
            this.setState({
              otpMessage:
                "A 4-digit verification code has been sent to your email.",
            });
            console.log("sent OTP successfully");
          })
          .catch((error) => {
            console.log("error while sending OTP");
          });
      }
    }
    this.setState({ companyProfile });
  };

  // verifyOtp(email, otp) {
  //   axios
  //     .post(awsUrl2 + "/api/verifyOtp", { email, otp })
  //     .then((res) => {
  //       if (res.data === "otp_verified") {
  //         this.setState({
  //           otpMessage: "Email verified successfully.",
  //           otpVerified: true,
  //         });
  //         this.fetchAndFillDetails();
  //       } else if (res.data === "otp_expired") {
  //         this.setState({
  //           otpMessage: "OTP expired.",
  //         });
  //       } else if (res.data === "invalid_otp") {
  //         this.setState({
  //           otpMessage: "Invalid OTP.",
  //         });
  //       } else if (res.data === "otp_not_generated") {
  //         this.setState({
  //           otpMessage: "OTP not generated.",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error while sending OTP");
  //       this.setState({
  //         otpMessage: "Unable to verify OTP.",
  //       });
  //     });
  // }

  // updateEmodInfo = (state, fieldName, value) => {
  //   let { emodStatesData } = this.state;
  //   let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
  //   if (dataFieldNames.includes(fieldName)) {
  //     emodStatesData[state][fieldName] = {
  //       value: value,
  //     };
  //   } else {
  //     emodStatesData[state][fieldName] = value;
  //   }

  //   this.setState({ emodStatesData });
  // };

  // amtrustResponse = (uuid, apiType) => {
  //   let promise = new Promise(async (resolve, reject) => {
  //     try {
  //       let res = await axios.get(
  //         awsUrl2 + `/api/getAmtrustData/${uuid}/${apiType}`
  //       );
  //       resolve(res.data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });

  //   return promise;
  // };

  formatPhoneNumber = (data) => {
    try {
      let phoneNumberString = data;
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      if (cleaned) {
        let len = cleaned.length;
        for (let i = 1; i <= 10 - len; i++) {
          cleaned = "0" + cleaned;
        }
        return cleaned;
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error while formatting phone number", error);
      return data;
    }
  };

  // first Check
  expectedStartDateCheck = (companyProfile, companyProfileCheck) => {
    let date2 = moment(companyProfile.effectiveDate.value);
    let date1 = moment();
    const diffInDays = Math.ceil(date2.diff(date1, "days", true));
    console.log(diffInDays, "diffInDays");

    if (diffInDays > 60) {
      companyProfileCheck["eligibility_check_2_1"]["eligibility"] = "referred";
    } else {
      companyProfileCheck["eligibility_check_2_1"]["eligibility"] = "approved";
    }
  };

  // second check
  totalEmployeeCheck = (totalEmployeeCount, companyProfileCheck) => {
    if (totalEmployeeCount > 50) {
      companyProfileCheck["eligibility_check_2_2"]["eligibility"] = "referred";
    } else {
      companyProfileCheck["eligibility_check_2_2"]["eligibility"] = "approved";
    }
  };
  // third Check
  EmodCheck = (emod, companyProfileCheck) => {
    if (Number(emod?.emodValue) > 1.2) {
      companyProfileCheck["eligibility_check_2_3"]["eligibility"] = "referred";
    } else {
      companyProfileCheck["eligibility_check_2_3"]["eligibility"] = "approved";
    }
  };

  //fourth check, checking only if we have more than 2 class code
  govPerCheck = (
    checkNumberofClassCode,
    companyProfileCheck,
    GovClassCodePayroll,
    totalAnnualPayroll,
    govCCPremium
  ) => {
    if (checkNumberofClassCode > 1) {
      let checkGovCCpercentage =
        Number(GovClassCodePayroll / totalAnnualPayroll) * 100;

      if (checkGovCCpercentage < 51) {
        if (Number(govCCPremium?.min?.value) > 10000) {
          companyProfileCheck["eligibility_check_2_5"]["eligibility"] =
            "referred";
        } else {
          companyProfileCheck["eligibility_check_2_5"]["eligibility"] =
            "declined";
        }
      } else {
        companyProfileCheck["eligibility_check_2_4"]["eligibility"] =
          "approved";
      }
    } else {
      console.log("we skipped the fourth check");
    }
  };

  //fifth Check

  govCC_MinPayroll = async (
    GovClassCodePayroll,
    companyProfileCheck,
    GovClassCode
  ) => {
    try {
      const minPremCheck = await axios.post(
        `${awsUrl2}/api/getMinpremiumGovCC/queryMinimumpayroll`,
        GovClassCode
      );
      const minPayroll = minPremCheck?.data?.[0]?.min_payroll;
      if (minPayroll && GovClassCodePayroll) {
        if (Number(GovClassCodePayroll) < Number(minPayroll)) {
          companyProfileCheck["eligibility_check_2_6"]["eligibility"] =
            "declined";
        } else {
          companyProfileCheck["eligibility_check_2_6"]["eligibility"] =
            "approved";
        }
      } else {
        console.log(
          "No minimum payroll found for this class code. Skipping..."
        );
      }
    } catch (error) {
      console.log(error, ":erroe while manual Payroll checkou");
    }
  };

  // sixth Check checking the 5606 classcode

  GovCC_5606_check = (
    check_5606_ClassCode,
    payroll_5606,
    companyProfileCheck,
    GovClassCodePayroll
  ) => {
    if (check_5606_ClassCode) {
      let get25percentGovCC = GovClassCodePayroll * 0.25;

      if (payroll_5606 >= get25percentGovCC) {
        companyProfileCheck["eligibility_check_2_7"]["eligibility"] =
          "referred";
      } else {
        companyProfileCheck["eligibility_check_2_7"]["eligibility"] =
          "approved";
      }
    }
  };

  // Sevent Check
  entityCheck = (additionalEntity, companyProfileCheck) => {
    if (additionalEntity["checked"] == true) {
      companyProfileCheck["eligibility_check_2_8"]["eligibility"] = "referred";
    } else {
      companyProfileCheck["eligibility_check_2_8"]["eligibility"] = "approved";
    }
  };

  // 2_10 check for secondary classcode with minpayroll check.
  secondaryClassCodeMinPayrollCheck = async (
    secondaryClasscodeCheckArray,
    companyProfileCheck
  ) => {
    try {
      let minPremCheck = await axios.post(
        `${awsUrl2}/api/getMinpremiumGovCC/scanMinimumpayroll`,
        secondaryClasscodeCheckArray
      );
      if (minPremCheck.data) {
        companyProfileCheck["eligibility_check_2_10"]["eligibility"] =
          minPremCheck.data;
      } else {
        companyProfileCheck["eligibility_check_2_10"]["eligibility"] = "";
      }
    } catch (error) {
      console.log(error, ":error at 2_10 check");
    }
  };

  companyProfileEligibilityCheck = async (
    companyProfile,
    additionalEntity,
    govCCPremium,
    emod,
    companyProfileCheck,
    totalEmployeeCount,
    checkNumberofClassCode,
    totalAnnualPayroll,
    GovClassCode,
    GovClassCodePayroll,
    check_5606_ClassCode,
    payroll_5606,
    secondaryClasscodeCheckArray
  ) => {
    this.expectedStartDateCheck(companyProfile, companyProfileCheck);
    this.totalEmployeeCheck(totalEmployeeCount, companyProfileCheck);
    this.EmodCheck(emod, companyProfileCheck);
    this.govPerCheck(
      checkNumberofClassCode,
      companyProfileCheck,
      GovClassCodePayroll,
      totalAnnualPayroll,
      govCCPremium
    );
    await this.govCC_MinPayroll(
      GovClassCodePayroll,
      companyProfileCheck,
      GovClassCode
    );
    this.GovCC_5606_check(
      check_5606_ClassCode,
      payroll_5606,
      companyProfileCheck,
      GovClassCodePayroll
    );
    this.entityCheck(additionalEntity, companyProfileCheck);
    await this.secondaryClassCodeMinPayrollCheck(
      secondaryClasscodeCheckArray,
      companyProfileCheck
    );
  };

  submitCompPro = async () => {
    const {
      childrenLoc,
      companyProfile,
      agentpersonCheck,
      agentpersonName,
      username,
      additionalEntity,
      emod,
      includedExcludedObject,
      govCCPremium,
      sameAsPrimary,
      historicalClaimsCheck,
    } = this.state;
    let domain = sessionStorage.getItem("domain");
    let agentID = sessionStorage.getItem("agentID");
    let landingEmail = sessionStorage.getItem("landingEmail");
    let dateNow = Math.floor(Date.now()).toString();
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let uuid_sp = uuidv4();
    let totalEmployeeCount = 0;
    let totalAnnualPayroll = 0;
    let checkNumberofClassCode = 0;
    let GovClassCode;
    let GovClassCodePayroll;
    let check_5606_ClassCode = false;
    let payroll_5606;
    let secondaryClasscodeCheckArray = {};
    for (let key in childrenLoc) {
      let loc1 = childrenLoc[key]["classCodesInfo"];

      for (let key in loc1) {
        checkNumberofClassCode++;

        totalEmployeeCount =
          totalEmployeeCount + Number(loc1?.[key]?.["ft"]?.["value"]);
        totalAnnualPayroll =
          totalAnnualPayroll +
          parseFloat(loc1?.[key]?.["payroll"]?.["value"]?.replace(/[$,]/g, ""));
        // checking and setting Gov CC and payroll
        if (loc1?.[key]?.["govClassCodeCheck"]?.["value"] === true) {
          GovClassCode = Number(
            loc1?.[key]?.["classCodeDescription"]?.["value"]?.split(":")[0]
          );

          GovClassCodePayroll = parseFloat(
            loc1?.[key]?.["payroll"]?.["value"]?.replace(/[$,]/g, "")
          );
        }
        // checking the 5606 classcode and getting payroll
        if (
          Number(
            loc1?.[key]?.["classCodeDescription"]?.["value"]?.split(":")[0]
          ) === 5606
        ) {
          payroll_5606 = parseFloat(
            loc1?.[key]?.["payroll"]?.["value"]?.replace(/[$,]/g, "")
          );
          check_5606_ClassCode = true;
        }

        // preparing the seconday classcode object to pass to secondaryclasscodefunction so that can get the 2_10 check.
        if (key == 0) {
          // skipping the first classcode so that we get only secondary classcode.
          continue;
        }
        secondaryClasscodeCheckArray[
          Number(
            loc1?.[key]?.["classCodeDescription"]?.["value"]?.split(":")[0]
          )
        ] = {};

        secondaryClasscodeCheckArray[
          Number(
            loc1?.[key]?.["classCodeDescription"]?.["value"]?.split(":")[0]
          )
        ]["classCode"] = Number(
          loc1?.[key]?.["classCodeDescription"]?.["value"]?.split(":")[0]
        );
        secondaryClasscodeCheckArray[
          Number(
            loc1?.[key]?.["classCodeDescription"]?.["value"]?.split(":")[0]
          )
        ]["payroll"] = parseFloat(
          loc1?.[key]?.["payroll"]?.["value"]?.replace(/[$,]/g, "")
        );
      }
    }

    let eligibility_companyProfileCheck = JSON.parse(
      JSON.stringify(this.props.eligibilityCheck)
    );
    resetEligibility(eligibility_companyProfileCheck, [
      "company_profile",
      "loss_history",
      "uw_questions",
      "quote_data",
    ]);

    let companyProfileCheck =
      eligibility_companyProfileCheck["company_profile"];

    await this.companyProfileEligibilityCheck(
      companyProfile,
      additionalEntity,
      govCCPremium,
      emod,
      companyProfileCheck,
      totalEmployeeCount,
      checkNumberofClassCode,
      totalAnnualPayroll,
      GovClassCode,
      GovClassCodePayroll,
      check_5606_ClassCode,
      payroll_5606,
      secondaryClasscodeCheckArray
    );
    this.props.setEligibilityCheck(eligibility_companyProfileCheck);

    // let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
    //   ? true
    //   : false;

    // let preUwEngagedFlag = sessionStorage.getItem("preUwEngagedFlag")
    //   ? true
    //   : false;

    // let amtrustAccountDetails = sessionStorage.getItem("amtrustAccountDetails");
    // try {
    //   amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
    // } catch (error) {}

    // let amtrustAccountDetailsCopy = sessionStorage.getItem(
    //   "amtrustAccountDetailsCopy"
    // );
    // try {
    //   amtrustAccountDetailsCopy = JSON.parse(amtrustAccountDetailsCopy);
    // } catch (error) {}

    // let amtrustDetails;
    // let addQuesData = [];
    // let generateAmtrustQuote = true;

    // if (companyProfileAddQuestions) {
    //   for (let question of companyProfileAddQuestions) {
    //     let dataQues = {
    //       response: question.response ? "Y" : "N",
    //       name: question.name,
    //       ques: question.ques,
    //     };
    //     if (question.name === "qc3") {
    //       dataQues.response = question.response;
    //       generateAmtrustQuote =
    //         dataQues.response === "b" || dataQues.response === "c"
    //           ? true
    //           : false;
    //     }
    //     addQuesData.push(dataQues);
    //   }
    // }

    // if (companyProfile?.companyName?.value) {
    // if (flagPreUnderWriter || preUwEngagedFlag) {
    //   try {
    //     let updateUnderWriterDetails = {};
    //     updateUnderWriterDetails.domain = sessionStorage.getItem("domain");
    //     updateUnderWriterDetails.fein = companyProfile?.fein?.value;
    //     updateUnderWriterDetails.companyProfile = companyProfile;
    //     updateUnderWriterDetails.payrollData = childrenLoc;

    //     await axios
    //       .post(
    //         awsUrl2 +
    //           `/api/preUwData/${
    //             preUwEngagedFlag ? "updateTrackingData" : "updateData"
    //           }`,
    //         updateUnderWriterDetails
    //       )
    //       .then((res) => {
    //         console.log("posted data", res);
    //       })
    //       .catch((error) => {
    //         console.log("error in posting data to user tracking", error);
    //       });
    //   } catch (error) {
    //     console.log("error while updating before Pre underwriter ", error);
    //   }
    // }

    // try {
    //   if (!domain || flagPreUnderWriter) throw new Error("!no domain found");

    //   let userTrackingData = {};
    //   userTrackingData.domain = domain;
    //   userTrackingData.companyProfile = companyProfile;
    //   userTrackingData.payrollData = childrenLoc;
    //   userTrackingData.emodStatesData = emodStatesData;
    //   userTrackingData.requestedPricing = requestedPricing;
    //   userTrackingData.companyProfileAddQuestions = addQuesData;

    //   if (salespersonMailCampaign) {
    //     userTrackingData.salespersonName = salespersonName;
    //     userTrackingData.salespersonNameAttr = salespersonNameAttr;
    //   }

    //   await axios
    //     .post(
    //       awsUrl2 + "/api/updateSolveTrackingDataGeneric/generic",
    //       JSON.stringify(userTrackingData)
    //     )
    //     .then(async (res) => {
    //       console.log("saved!");
    //       let updateEmailSpecific = {
    //         companyProfile,
    //         domain,
    //         email: landingEmail,
    //       };
    //       await axios.post(
    //         awsUrl2 +
    //           "/api/updateSolveTrackingDataGeneric/updateEmailSpecific",
    //         JSON.stringify(updateEmailSpecific)
    //       );
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // } catch (error) {
    //   console.log("error while updating user tracking flow ", error);
    // }

    //   try {
    //     if (salespersonCheck && !salespersonMailCampaign) {
    //       // console.log("salespersonNameAttr: ", salespersonNameAttr);
    //       let salesPersonData = {};
    //       let type = "insert";

    //       if (salesID) {
    //         type = "update";
    //       } else {
    //         type = "insert";
    //         salesID = `insurecomp_${uuid_sp}_${dateNow}`;
    //         sessionStorage.setItem("salesID", salesID.toLowerCase());
    //       }

    //       let compName = companyProfile.companyName.value;
    //       let fein = companyProfile.fein.value;

    //       let isSalesforce = sessionStorage.getItem("isSalesforce");

    //       salesPersonData.id = salesID.toLowerCase();
    //       salesPersonData.companyProfile = companyProfile;
    //       salesPersonData.payrollData = childrenLoc;
    //       salesPersonData.emodStatesData = emodStatesData;
    //       salesPersonData.companyProfileAddQuestions = addQuesData;
    //       if (!isSalesforce) {
    //         salesPersonData.created_timestamp = salesID
    //           .toLowerCase()
    //           .split("_")
    //           .pop();
    //       }
    //       salesPersonData.updated_timestamp = dateNow;
    //       salesPersonData.origin = "salesperson";
    //       salesPersonData.companyName = compName;
    //       salesPersonData.fein = fein;
    //       salesPersonData.salespersonName = salespersonName;
    //       salesPersonData.requestedPricing = requestedPricing;
    //       salesPersonData.salespersonNameAttr = salespersonNameAttr;
    //       salesPersonData.brokerDetails = brokerDetails;

    //       await axios
    //         .post(
    //           awsUrl2 + `/api/salesPersonData/${type}`,
    //           JSON.stringify(salesPersonData)
    //         )
    //         .then(() => {})
    //         .catch((error) => {
    //           console.log("error", error);
    //         });
    //     }
    //   } catch (error) {
    //     console.log("sales person flow error: ", error);
    //   }
    // }

    let mailCampaignUserCheck = checkMailCampaignUser();
    let [companyProfileError, companyfield] = await this.checkCompanyProfileError();
    let [locationInfoError, locationInfoField] = this.checkLocationInfoError();
    let { includeExcludeError } = this.validateIncludeExcludedHandler();
    let { additionalEntityError } = this.ValidatAddintionalEntityHandler();

    try {
      if (companyProfileError) {
        document.getElementById(companyfield).focus();
      } else if (additionalEntityError === true) {
        document
          .getElementById("additionalEntityContainer")
          .scrollIntoView({ behavior: "smooth" });
      } else if (locationInfoError) {
        document
          .getElementById(locationInfoField)
          .scrollIntoView({ behavior: "smooth" });
      } else if (includeExcludeError == true) {
        document
          .getElementById("IncludeExcludeContainer")
          .scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {}

    if (
      !companyProfileError &&
      !locationInfoError &&
      !additionalEntityError &&
      !includeExcludeError
    ) {
      $("#loader").css("display", "block");
      //Pre underwriter update

      // if (flagPreUnderWriter && !preUwEngagedFlag) {
      //   try {
      //     let updateUnderWriterDetails = {};
      //     updateUnderWriterDetails.domain = sessionStorage.getItem("domain");
      //     updateUnderWriterDetails.fein =
      //       currProspect?.companyProfile?.fein?.value;
      //     updateUnderWriterDetails.companyProfile = companyProfile;
      //     updateUnderWriterDetails.payrollData = childrenLoc;
      //     updateUnderWriterDetails.remove = true;
      //     updateUnderWriterDetails.clientEligibility = "";
      //     updateUnderWriterDetails.uwFormStage = "two";
      //     // updateUnderWriterDetails.formStage = "two";
      //     // updateUnderWriterDetails.pre_underwriter_status = "uw";

      //     await axios
      //       .post(
      //         awsUrl2 + "/api/preUwData/updateData",
      //         updateUnderWriterDetails
      //       )
      //       .then((res) => {
      //         console.log("posted data", res);
      //       })
      //       .catch((error) => {
      //         console.log("error in posting data to user tracking", error);
      //       });
      //   } catch (error) {
      //     console.log("error while updating before Pre underwriter ", error);
      //   }
      //   sessionStorage.removeItem("clientEligibility");
      // } else if (!preUwEngagedFlag && generateAmtrustQuote) {
      //   //prepare the request body for the amtrust create quote API
      //   try {
      //     let amtrustQuoteRequestBody = { Quote: {} };

      //     amtrustQuoteRequestBody.Quote = {
      //       EffectiveDate: moment(
      //         companyProfile.effectiveDate.value,
      //         "YYYY-MM-DD"
      //       ).format("MM/DD/YYYY"),
      //       Fein: companyProfile.fein.value.split("-").join(""),
      //       IsIncumbentAgent: false,
      //       NatureOfBusiness: "Hospitality",
      //       IsNonProfit: false,
      //       LegalEntity: 0,
      //       EntityType: companyProfile.entityType.value,
      //       BusinessName: companyProfile.companyName.value,
      //       YearsInBusiness: companyProfile.yearsInBusiness.value,
      //       PrimaryAddress: {
      //         Line1: companyProfile.street1.value,
      //         Line2: "",
      //         City: companyProfile.cityName1.value,
      //         State: companyProfile.state1.value.toUpperCase(),
      //         Zip: companyProfile.zipCode1.value,
      //       },
      //       MailingAddress: {
      //         Line1: companyProfile.street2.value,
      //         Line2: "",
      //         City: companyProfile.cityName2.value,
      //         State: companyProfile.state2.value.toUpperCase(),
      //         Zip: companyProfile.zipCode2.value,
      //       },
      //       ContactInformation: {
      //         FirstName: companyProfile.firstName.value,
      //         LastName: companyProfile.lastName.value,
      //         Phone: this.formatPhoneNumber(companyProfile.phoneNumber.value),
      //       },
      //       ClassCodes: [],
      //     };

      //     let amtrustClassCodesByState = {};
      //     for (let stateIndex in childrenLoc) {
      //       let state = childrenLoc[stateIndex].state.value;
      //       for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
      //         let cc, desc, ccdescArray, ccdesc;
      //         let classCodeObj = {};
      //         ccdesc =
      //           childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
      //             .classCodeDescription.value;
      //         ccdescArray = ccdesc.split(":");
      //         cc = ccdescArray[0];
      //         desc = ccdescArray
      //           .slice(1)
      //           .join(":")
      //           .trim();

      //         classCodeObj = {
      //           ClassCode: String(cc).padStart(4, "0"),
      //           ClassCodeDescription: desc,
      //           State: state.toUpperCase(),
      //           Payroll: numeral(
      //             childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
      //               .value
      //           ).value(),
      //           FullTimeEmployees: numeral(
      //             childrenLoc[stateIndex].classCodesInfo[classCodeIndex].ft
      //               .value
      //           ).value(),
      //           PartTimeEmployees: numeral(
      //             childrenLoc[stateIndex].classCodesInfo[classCodeIndex].pt
      //               .value
      //           ).value(),
      //         };

      //         if (!amtrustClassCodesByState[state]) {
      //           amtrustClassCodesByState[state] = {};
      //         }

      //         if (!amtrustClassCodesByState[state][cc]) {
      //           amtrustClassCodesByState[state][cc] = classCodeObj;
      //         } else {
      //           amtrustClassCodesByState[state][cc].Payroll += numeral(
      //             classCodeObj.Payroll
      //           ).value();
      //           amtrustClassCodesByState[state][
      //             cc
      //           ].FullTimeEmployees += numeral(
      //             classCodeObj.FullTimeEmployees
      //           ).value();
      //           amtrustClassCodesByState[state][
      //             cc
      //           ].PartTimeEmployees += numeral(
      //             classCodeObj.PartTimeEmployees
      //           ).value();
      //         }
      //       }
      //     }

      //     for (let state in amtrustClassCodesByState) {
      //       for (let classCode in amtrustClassCodesByState[state]) {
      //         amtrustQuoteRequestBody.Quote.ClassCodes.push(
      //           amtrustClassCodesByState[state][classCode]
      //         );
      //       }
      //     }

      //     let amtrustQuoteId =
      //       amtrustAccountDetails?.QuoteId ||
      //       amtrustAccountDetailsCopy?.QuoteId;
      //     if (amtrustQuoteId) {
      //       let amtrustTriggerResponse = await axios.post(
      //         awsUrl2 + "/api/triggerAmtrustQuoteUpdate/" + amtrustQuoteId,
      //         amtrustQuoteRequestBody.Quote
      //       );

      //       let uuid;
      //       if (amtrustTriggerResponse.data.uuid) {
      //         uuid = amtrustTriggerResponse.data.uuid;
      //       } else {
      //         throw "error in amtrust update quote trigger";
      //       }

      //       let amtrustQuoteResponse;
      //       do {
      //         amtrustQuoteResponse = await this.amtrustResponse(
      //           uuid,
      //           "updateQuote"
      //         );
      //         // amtrustQuoteResponse = "error";

      //         if (amtrustQuoteResponse === "error") {
      //           throw "error in amtrust quote updation";
      //         }
      //       } while (amtrustQuoteResponse === "processing");

      //       if (amtrustQuoteResponse.StatusCode === "200") {
      //         if (!amtrustAccountDetails?.QuoteId) {
      //           amtrustDetails = amtrustAccountDetailsCopy;
      //           sessionStorage.setItem(
      //             "amtrustAccountDetails",
      //             JSON.stringify(amtrustAccountDetailsCopy)
      //           );
      //         }
      //       } else {
      //         console.log(
      //           "unable to create amtrust quote",
      //           amtrustQuoteResponse
      //         );
      //         sessionStorage.removeItem("amtrustAccountDetails");
      //       }
      //     } else {
      //       let amtrustTriggerResponse = await axios.post(
      //         awsUrl2 + "/api/triggerAmtrustQuoteGeneration",
      //         amtrustQuoteRequestBody
      //       );

      //       let uuid;
      //       if (amtrustTriggerResponse.data.uuid) {
      //         uuid = amtrustTriggerResponse.data.uuid;
      //       } else {
      //         throw "error in amtrust create quote trigger";
      //       }

      //       let amtrustQuoteResponse;
      //       do {
      //         amtrustQuoteResponse = await this.amtrustResponse(
      //           uuid,
      //           "createQuote"
      //         );
      //         // amtrustQuoteResponse = "error";

      //         if (amtrustQuoteResponse === "error") {
      //           throw "error in amtrust quote generation";
      //         }
      //       } while (amtrustQuoteResponse === "processing");

      //       if (amtrustQuoteResponse.Message === "Quote created") {
      //         amtrustDetails = amtrustQuoteResponse.Data.AccountInformation;
      //         sessionStorage.setItem(
      //           "amtrustAccountDetails",
      //           JSON.stringify(amtrustQuoteResponse.Data.AccountInformation)
      //         );
      //         sessionStorage.setItem(
      //           "amtrustAccountDetailsCopy",
      //           JSON.stringify(amtrustQuoteResponse.Data.AccountInformation)
      //         );
      //       } else {
      //         console.log(
      //           "unable to create amtrust quote",
      //           amtrustQuoteResponse
      //         );
      //         sessionStorage.removeItem("amtrustAccountDetails");
      //       }
      //     }
      //   } catch (error) {
      //     console.log("error while generating amtrust quote", error);
      //     sessionStorage.removeItem("amtrustAccountDetails");
      //   }
      // }

      // try {
      //   if (preUwEngagedFlag) {
      //     try {
      //       let updateUnderWriterDetails = {};
      //       updateUnderWriterDetails.domain = sessionStorage.getItem("domain");
      //       updateUnderWriterDetails.fein =
      //         currProspect?.companyProfile?.fein?.value;
      //       updateUnderWriterDetails.companyProfile = companyProfile;
      //       updateUnderWriterDetails.payrollData = childrenLoc;
      //       updateUnderWriterDetails.remove = true;
      //       updateUnderWriterDetails.clientEligibility = "";
      //       updateUnderWriterDetails.uwFormStage = "two";
      //       // updateUnderWriterDetails.formStage = "two";
      //       // updateUnderWriterDetails.pre_underwriter_status = "uw";

      //       await axios
      //         .post(
      //           awsUrl2 + "/api/preUwData/updateTrackingData",
      //           updateUnderWriterDetails
      //         )
      //         .then((res) => {
      //           console.log("posted data", res);
      //         })
      //         .catch((error) => {
      //           console.log("error in posting data to user tracking", error);
      //         });
      //     } catch (error) {
      //       console.log("error while updating before Pre underwriter ", error);
      //     }
      //     sessionStorage.removeItem("clientEligibility");
      //   }
      // } catch (error) {
      //   console.log("error: ", error);
      // }

      // try {
      //   if (!domain || flagPreUnderWriter) throw new Error("!no domain found");

      //   let userTrackingData = {};
      //   userTrackingData.domain = domain;
      //   userTrackingData.companyProfile = companyProfile;
      //   userTrackingData.payrollData = childrenLoc;
      //   userTrackingData.emodStatesData = emodStatesData;
      //   userTrackingData.requestedPricing = requestedPricing;
      //   userTrackingData.companyProfileAddQuestions = addQuesData;

      //   if (salespersonMailCampaign) {
      //     userTrackingData.salespersonName = salespersonName;
      //     userTrackingData.salespersonNameAttr = salespersonNameAttr;
      //   }

      //   if (amtrustDetails) {
      //     userTrackingData.amtrustAccountDetails = amtrustDetails;
      //     userTrackingData.amtrustAccountDetailsCopy = amtrustDetails;
      //   } else {
      //     userTrackingData.amtrustAccountDetails = {};
      //   }

      //   await axios
      //     .post(
      //       awsUrl2 + "/api/updateSolveTrackingDataGeneric/generic",
      //       JSON.stringify(userTrackingData)
      //     )
      //     .then(async (res) => {
      //       let updateEmailSpecific = {
      //         companyProfile,
      //         domain,
      //         email: landingEmail,
      //       };
      //       await axios
      //         .post(
      //           awsUrl2 +
      //             "/api/updateSolveTrackingDataGeneric/updateEmailSpecific",
      //           JSON.stringify(updateEmailSpecific)
      //         )
      //         .then((res) => {
      //           console.log("updated emailSpecific");
      //         })
      //         .catch((error) => {
      //           console.log("error", error);
      //         });
      //       let requestBody = {
      //         domain,
      //         formStage: "two",
      //         email: landingEmail,
      //       };
      //       await axios
      //         .post(
      //           awsUrl2 + "/api/updateSolveTrackingDataGeneric/updateFormStage",
      //           JSON.stringify(requestBody)
      //         )
      //         .then((res) => {
      //           console.log("updated formstage two");
      //         })
      //         .catch((error) => {
      //           console.log("error", error);
      //         });
      //       console.log("updated tracking data");
      //     })
      //     .catch((error) => {
      //       console.log("error", error);
      //     });
      // } catch (error) {
      //   console.log("error while updating user tracking flow ", error);
      // }
      if (companyProfile?.companyName?.value) {
        try {
          if (agentpersonCheck) {
            let agentPersonData = {};
            let type = "insert";

            if (agentID) {
              type = "update";
            } else {
              type = "insert";
            }

            if (sameAsPrimary) {
              currProspect = {
                ...currProspect,
                sameAsPrimary: sameAsPrimary,
              };
            }

            let sessionData = JSON.parse(
              sessionStorage.getItem("currProspect")
            );
            let agency_name = sessionData.agency_name;
            let agent_name = sessionData.agent_name;
            let uw_name = sessionData.uw_name;
            let agency_code = sessionData.agency_code;
            let govClassCodeObject = sessionData.govClassCodeObject;
            currProspect = {
              ...currProspect,
              agency_name,
              agent_name,
              uw_name,
              agency_code,
              govClassCodeObject,
              childrenLoc: childrenLoc,
              companyProfile: companyProfile,
              uwQues: "",
              additionalEntity: additionalEntity,
              includedExcludedObject: includedExcludedObject,
              emod: emod,
              historicalClaims: {},
            };

            let compName = companyProfile.companyName.value;
            let fein = companyProfile.fein.value;
            agentPersonData.id = agentID.toLowerCase();
            agentPersonData.companyProfile = companyProfile;
            agentPersonData.payrollData = childrenLoc;
            agentPersonData.currProspect = currProspect;
            agentPersonData.updated_timestamp = dateNow;
            agentPersonData.origin = "Agentperson";
            agentPersonData.companyName = compName;
            agentPersonData.fein = fein;
            agentPersonData.uwQues = "";
            agentPersonData.agentEmail = agentpersonName;
            agentPersonData.agentName = username;
            agentPersonData.eligibilityCheck = eligibility_companyProfileCheck;
            agentPersonData.UWeligibilityCheckStatus = "";
            agentPersonData.isFuba_hash_key = "true";

            await axios
              .post(
                awsUrl2 + `/api/agentPersonData/${type}`,
                JSON.stringify(agentPersonData)
              )
              .then((res) => {})
              .catch((error) => {
                console.log("error", error);
              });
          }
        } catch (error) {
          console.log("sales person flow error: ", error);
        }
      }
      let sessionData = JSON.parse(sessionStorage.getItem("currProspect"));
      let agency_name = sessionData.agency_name;
      let agent_name = sessionData.agent_name;
      let uw_name = sessionData.uw_name;
      let agency_code = sessionData.agency_name;

      if (companyProfile?.companyName?.value) {
        try {
          if (agentpersonCheck) {
            // console.log("salespersonNameAttr: ", salespersonNameAttr);
            let agentPersonData = {};
            let type = "insert";

            if (agentID) {
              type = "update";
            } else {
              type = "insert";
            }

            let compName = companyProfile.companyName.value;
            let fein = companyProfile.fein.value;
            agentPersonData.id = agentID.toLowerCase();
            agentPersonData.companyProfile = companyProfile;
            agentPersonData.payrollData = childrenLoc;
            agentPersonData.currProspect = currProspect;
            agentPersonData.updated_timestamp = dateNow;
            agentPersonData.origin = "Agentperson";
            agentPersonData.formStage = "two";
            agentPersonData.companyName = compName;
            agentPersonData.fein = fein;
            agentPersonData.uwQues = "";
            agentPersonData.agentEmail = agentpersonName;
            agentPersonData.agentName = username;
            agentPersonData.eligibilityCheck = eligibility_companyProfileCheck;
            agentPersonData.UWeligibilityCheckStatus = "";

            sessionStorage.setItem(
              "currProspect",
              JSON.stringify(currProspect)
            );
            // agentPersonData.emodStatesData = emodStatesData;
            // agentPersonData.companyProfileAddQuestions = addQuesData;
            // if (!isSalesforce) {
            //   agentPersonData.created_timestamp = agentID
            //     .toLowerCase()
            //     .split("_")
            //     .pop();
            // }
            // agentPersonData.salespersonName = salespersonName;
            // // salesPersonData.requestedPricing = requestedPricing;
            // agentPersonData.salespersonNameAttr = salespersonNameAttr;
            // salesPersonData.brokerDetails = brokerDetails;
            // if (amtrustDetails) {
            //   salesPersonData.amtrustAccountDetails = amtrustDetails;
            //   salesPersonData.amtrustAccountDetailsCopy = amtrustDetails;
            // } else {
            //   salesPersonData.amtrustAccountDetails = {};
            // }

            await axios
              .post(
                awsUrl2 + `/api/agentPersonData/${type}`,
                JSON.stringify(agentPersonData)
              )
              .then((res) => {
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem("formStage", "two");
                window.location.reload();
              })
              .catch((error) => {
                console.log("error", error);
              });
          }
        } catch (error) {
          console.log("sales person flow error: ", error);
        }
      }

      
    }
  };

  getDomainFromEmail = (user) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let response;
        let generalDomainNames = [
          "gmail",
          "yahoo",
          "aol",
          "mac",
          "cox",
          "frontier",
          "msn",
          "gammana",
          "hotmail",
          "pacbell",
          "att",
          "sbcglobal",
          "windstream",
          "verizon",
          "comcast",
          "ampcocontracting",
        ];
        let tempBusinessName = user.split("@")[1].split(".");
        tempBusinessName.pop();
        let business_name = tempBusinessName.join(".");
        if (generalDomainNames.includes(business_name)) {
          response = user.split("@")[0];
        } else {
          response = business_name;
        }

        let domainResp = await axios.get(awsUrl2 + "/api/getDomain/" + user);
        if (domainResp.data && domainResp.data.domain) {
          response = domainResp.data.domain;
        }

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  fetchAndFillDetails = async () => {
    try {
      $("#loader").css("display", "block");
      let user = this.state.companyProfile.emailId.value;

      await axios
        .get(awsUrl + "/api/getUserDataStatus/" + user + "/quote")
        .then(async (response) => {
          if (response.data) {
            let carrierBasedData = response.data.carrierBasedData;
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];
                let childrenLoc = {};

                let uwQues = lineObj.uw_ques;

                let companyProfile = {
                  companyName: { value: lineObj.company_name },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  fein: { value: lineObj.fein },
                  emailId: { value: lineObj.user_email_id },
                  phoneNumber: { value: lineObj.phone_number },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },

                  healthQues: {
                    value:
                      lineObj.healthQues === true ||
                      lineObj.healthQues === false
                        ? lineObj.healthQues
                        : true,
                  },
                };

                let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
                let discountData = lineObj.discountData;

                if (discountData && discountData.value) {
                  if (discountData.type === "percent") {
                    let valueToSub = numeral(adminFeeTemp)
                      .multiply(discountData.value)
                      .divide(100)
                      .value();
                    adminFeeTemp = numeral(adminFeeTemp)
                      .subtract(valueToSub)
                      .value();
                  } else {
                    adminFeeTemp = discountData.value;
                  }
                }

                let quoteFactors = {
                  ficaCharge: lineObj.carrier_location_data.fica,
                  futaCharge: lineObj.carrier_location_data.futa,
                  sutaCharge: lineObj.carrier_location_data.suta,
                  teap:
                    lineObj.carrier_location_data
                      .total_estimated_annual_premium,
                  total: lineObj.carrier_location_data.total,
                  adminFee: adminFeeTemp,
                  payroll: 0,
                };

                let emodStatesData = lineObj.emodStatesData;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                for (let prospectAddressesIndx in lineObj.prospect_addresses) {
                  let prospectAddressesObj =
                    lineObj.prospect_addresses[prospectAddressesIndx];

                  let rand = Math.random();
                  childrenLoc[prospectAddressesIndx] = {
                    cityName: {},
                    classCodesInfo: {},
                    key: rand,
                    number: prospectAddressesObj.addressId,
                    state: { value: prospectAddressesObj.stateCode },
                    zipcode: {},
                  };
                  for (let classCodesIndx in prospectAddressesObj.classCodes) {
                    var formatter = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
                    let classCodesObj =
                      prospectAddressesObj.classCodes[classCodesIndx];
                    childrenLoc[prospectAddressesIndx]["classCodesInfo"][
                      classCodesIndx
                    ] = {
                      classCodeDescription: {
                        value: `${classCodesObj.code}: ${classCodesObj.description}`,
                      },
                      ft: { value: classCodesObj.ft },
                      pt: { value: 0 },
                      payroll: {
                        value: formatter.format(classCodesObj.payroll),
                      },
                    };
                    quoteFactors.payroll += numeral(
                      classCodesObj.payroll
                    ).value();
                  }
                }
                currProspect = {
                  childrenLoc,
                  companyProfile,
                  uwQues,
                  emodStatesData,
                };
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem(
                  "childrenLoc",
                  JSON.stringify(childrenLoc)
                );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                sessionStorage.setItem("formStage", "three");
                sessionStorage.setItem(
                  "quoteFactors",
                  JSON.stringify(quoteFactors)
                );
              }
            }
            $("#loader").css("display", "none");
            window.location.reload();
          } else {
            let domain;
            try {
              domain = await this.getDomainFromEmail(user);
            } catch (error) {
              console.log("error in getting domain", error);
            }

            sessionStorage.setItem("domain", domain);
            axios
              .get(awsUrl2 + "/api/getDomainInputData/" + domain)
              .then((response) => {
                if (response.status === 200 && response.data.email_specific) {
                  let {
                    companyProfile,
                    childrenLoc,
                    emodStatesData,
                    indicationCost,
                    email_specific,
                  } = response.data;

                  if (email_specific) {
                    if (user in email_specific) {
                      companyProfile = email_specific[user].companyProfile;
                    } else {
                      companyProfile.emailId.value = user;
                    }
                  }

                  console.log("companyProfile: ", companyProfile);
                  console.log("emodStatesData: ", emodStatesData);

                  for (let emodKey in emodStatesData) {
                    if (emodStatesData[emodKey].date1.value)
                      emodStatesData[emodKey].date1.value = moment(
                        emodStatesData[emodKey].date1.value
                      );
                    if (emodStatesData[emodKey].date2.value)
                      emodStatesData[emodKey].date2.value = moment(
                        emodStatesData[emodKey].date2.value
                      );
                  }

                  if (!("healthQues" in companyProfile)) {
                    companyProfile.healthQues = { value: true };
                  }

                  let currProspect = {
                    childrenLoc,
                    companyProfile,
                    emodStatesData,
                  };

                  sessionStorage.setItem(
                    "currProspect",
                    JSON.stringify(currProspect)
                  );
                  this.setState({
                    childrenLoc,
                    companyProfile,
                    emodStatesData,
                  });
                }
                $("#loader").css("display", "none");
              });
          }
          sessionStorage.setItem("otpVerified", "true");
        })
        .catch((error) => {
          console.log("error in data retrieval");
          $("#loader").css("display", "none");
        });
    } catch (error) {
      console.log("error in GET button click");
    }
  };

  // updateHealthDropDown(event) {
  //   let { companyProfile } = this.state;
  //   companyProfile.healthQues.value =
  //     event.target.value === "true" ? true : false;
  //   this.setState({ companyProfile });
  // }

  handleSameAsPrimaryCheck = () => {
    let { sameAsPrimary, companyProfile } = this.state;
    console.log("companyProfile: ", companyProfile);
    if (sameAsPrimary) {
      companyProfile.cityName1 = { value: "" };
      companyProfile.state1 = { value: "" };
      companyProfile.zipCode1 = { value: "" };
      companyProfile.street1 = { value: "" };
      this.setState({ sameAsPrimary: false, companyProfile });
    } else {
      if (
        companyProfile?.state2?.value == "FL" ||
        companyProfile?.state2?.value == "fl"
      ) {
        companyProfile.cityName1 = companyProfile.cityName2;
        companyProfile.state1 = companyProfile.state2;
        companyProfile.zipCode1 = companyProfile.zipCode2;
        companyProfile.street1 = companyProfile.street2;
        this.setState({ sameAsPrimary: true, companyProfile });
      }
    }
  };

  handleLocSameAsPrimaryCheck = () => {
    let { locSameAsPrimary, childrenLoc, companyProfile } = this.state;
    if (locSameAsPrimary) {
      childrenLoc[Object.keys(childrenLoc)[0]].cityName = "";
      childrenLoc[Object.keys(childrenLoc)[0]].state = "";
      childrenLoc[Object.keys(childrenLoc)[0]].zipCode = "";
      childrenLoc[Object.keys(childrenLoc)[0]].street = "";

      this.setState({ locSameAsPrimary: false, childrenLoc });
    } else {
      childrenLoc[Object.keys(childrenLoc)[0]].cityName =
        companyProfile.cityName1;
      childrenLoc[Object.keys(childrenLoc)[0]].state = companyProfile.state1;
      childrenLoc[Object.keys(childrenLoc)[0]].zipCode =
        companyProfile.zipCode1;
      childrenLoc[Object.keys(childrenLoc)[0]].street = companyProfile.street1;

      this.setState({ locSameAsPrimary: true, childrenLoc });
    }
  };

  condition_render_font = () => {
    let style = {};
    if (window.screen.width < 1350 && window.screen.width > 992) {
      style = {
        fontSize: "13px",
      };
      return style;
    }
  };

  // emod Handler and setting it state

  emodChangeHandler = (e) => {
    let { emod } = JSON.parse(JSON.stringify(this.state));

    emod["emodValue"] = e.target.value;
    this.setState({ emod });
  };

  render() {
    const {
      companyProfile,
      childrenLoc,
      salesLogin,
      showCompanyProfile,
      showLocationAndPayroll,
      sameAsPrimary,
      locSameAsPrimary,
      adminpersonCheck,
      additionalEntity,
      emod,
      includedExcludedObject,
      agentpersonCheck,
      feinAgencyError,
      // historicalClaims,
      // historicalClaimsCheck,
      // historicalClaimsError,

      // emodStatesData,

      // otpVerified,
      // otpMessage,
      // otpValue,

      // showAdditionalQuestions,

      // requestedPricing,

      // salesManagerCheck,
      // salesManagerPersonName,
    } = this.state;
    const currProspect = sessionStorage.getItem("currProspect");
    // const table = this.createTable();
    let index = 0;

    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;
    let showAllFields = sessionStorage.getItem("showAllFields") ? true : false;

    let disableGetQuote = sessionStorage.getItem("disableGetQuote");
    let disableContinue = sessionStorage.getItem("disableContinue");

    //flagPreUnderWriter
    // let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
    //   ? true
    //   : false;

    // let isUnderWriterFlow = sessionStorage.getItem("preUnderwriter") === "true";

    // let mailCampaignUserCheck = checkMailCampaignUser();
    const titleText = "";
    var isLoggedIn =
      sessionStorage.getItem("isLoggedIn") === undefined ||
      sessionStorage.getItem("isLoggedIn") === null
        ? false
        : sessionStorage.getItem("isLoggedIn");
    let locationCount = 1;

    let viewOnly = sessionStorage.getItem("viewOnly")
      ? JSON.parse(sessionStorage.getItem("viewOnly"))
      : false;

    let allStates1 = [
      /*<option key={0} value="">
      State
    </option>*/

      <option key={1} value={"FL".toLowerCase()}>
        FL
      </option>,
    ];
    // for (var item of stateCity) {
    //   if (
    //     companyProfile &&
    //     item ===
    //       (companyProfile.state1 && companyProfile.state1.value
    //         ? companyProfile.state1.value
    //         : "")
    //   ) {
    //     allStates1.push(
    //       <option key={item.Code} value={item.Code.toLowerCase()} selected>
    //         {item.Code}
    //       </option>
    //     );
    //   } else {
    //     allStates1.push(
    //       <option key={item.Code} value={item.Code.toLowerCase()}>
    //         {item.Code}
    //       </option>
    //     );
    //   }
    // }
    let allStates2 = [
      <option key={0} value="">
        State
      </option>,
    ];
    for (var item of stateCity) {
      if (
        companyProfile &&
        item ===
          (companyProfile.state2 && companyProfile.state2.value
            ? companyProfile.state2.value
            : "")
      ) {
        allStates2.push(
          <option key={item.Code} value={item.Code.toLowerCase()} selected>
            {item.Code}
          </option>
        );
      } else {
        allStates2.push(
          <option key={item.Code} value={item.Code.toLowerCase()}>
            {item.Code}
          </option>
        );
      }
    }

    let locAndPrimaryAreSame = false;
    let firstChildrenOb = childrenLoc[Object.keys(childrenLoc)[0]];
    if (
      firstChildrenOb?.state?.value === companyProfile?.state1?.value &&
      firstChildrenOb?.cityName?.value === companyProfile?.cityName1?.value &&
      firstChildrenOb?.zipCode?.value === companyProfile?.zipCode1?.value &&
      firstChildrenOb?.street?.value === companyProfile?.street1?.value &&
      firstChildrenOb?.state?.value &&
      firstChildrenOb?.cityName?.value &&
      firstChildrenOb?.zipCode?.value &&
      firstChildrenOb?.street?.value
    ) {
      locAndPrimaryAreSame = true;
    }

    if (locAndPrimaryAreSame && !locSameAsPrimary) {
      this.setState({ locSameAsPrimary: true });
    } else if (!locAndPrimaryAreSame && locSameAsPrimary) {
      this.setState({ locSameAsPrimary: false });
    }

    try {
      locationCount = Object.keys(childrenLoc).length;
    } catch (e) {}
    return (
      <div id="compPro" className="container-fluid active per98">
        <NewProgress />
        <div className="parse-acord mb-5">
          <div className="pink-header-parse m-0 p-0 mb-3">
            <div
              className="font-family-montserrat-bold"
              style={{ color: "black" }}
            ></div>
          </div>
          <UploadAcordFile
            updateFormFields={this.updateFormFields}
            childrenLoc={childrenLoc}
          />
        </div>

        <div id="compProfile" style={this.condition_render_font()}>
          <form autoComplete="none">
            <div className="formTitle">
              <b className="font-family-montserrat-bold">{titleText}</b>
            </div>
            <div className="pink-header">
              <div className="font-family-montserrat-bold">Company Profile</div>
              {/* {showCompanyProfile ? (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (showCompanyProfile)
                      this.setState({ showCompanyProfile: false });
                    else return;
                  }}
                >
                  &#8722;
                </span>
              ) : (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (!showCompanyProfile)
                      this.setState({ showCompanyProfile: true });
                    else return;
                  }}
                >
                  &#43;
                </span>
              )} */}
            </div>
            {showCompanyProfile && (
              <div className="company-profile-container">
                <div className="row" id="company-profile-row1">
                  <div className="col-lg-6 col-md-12 grey">
                    <Form.Group>
                      <label htmlFor="companyName" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Company Name
                        </b>
                      </label>
                      <input
                        value={
                          companyProfile.companyName &&
                          companyProfile.companyName.value
                            ? companyProfile.companyName.value
                            : ""
                        }
                        id="companyName"
                        type="text"
                        style={
                          companyProfile.companyName &&
                          companyProfile.companyName.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "companyName")
                        }
                        placeholder="Company Name"
                        autoComplete="off"
                        className="rectangle1"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor="descriptionOfOperations"
                        title="Description of Operations"
                        className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Description of Operations
                        </b>
                      </label>

                      <input
                        value={
                          companyProfile.descriptionOfOperations &&
                          companyProfile.descriptionOfOperations.value
                            ? companyProfile.descriptionOfOperations.value
                            : ""
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(
                            event,
                            "descriptionOfOperations"
                          )
                        }
                        style={
                          companyProfile.descriptionOfOperations &&
                          companyProfile.descriptionOfOperations.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="Description of Operations"
                        id="descriptionOfOperations"
                        type="text"
                        // onKeyUp={onlyAlpha}
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                      <span></span>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" id="company-profile-row2">
                  <div className="col-lg-3 col-md-12">
                    <Form.Group>
                      <label htmlFor="fein" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">FEIN</b>
                      </label>
                      <input
                        placeholder="FEIN"
                        value={
                          companyProfile.fein ? companyProfile.fein.value : ""
                        }
                        onChange={(event) => {
                          this.updateCompanyProfile(event, "fein");
                        }}
                        style={
                          companyProfile.fein && companyProfile.fein.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        id="fein"
                        type="text"
                        autoComplete="off"
                        onInput={(event) => {
                          event.target.value = event.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*)\./g, "$1");
                        }}
                        onBlur={async (event) => {
                          var value = event.target.value.toString().trim();
                          value = value.split("-").join("");
                          var len = value.length,
                            dummystr = "000000000";

                          if (len > 0 && len < 9) {
                            value = dummystr.slice(0, 9 - len) + "" + value;
                            companyProfile.fein.value =
                              value[0] + value[1] + "-" + value.slice(2);
                            companyProfile.fein.errMsg = "";
                          } else if (len > 9) {
                            companyProfile.fein.errMsg =
                              "FEIN needs to be 9 digits";
                            companyProfile.fein.error = true;
                          } else if (len === 0) {
                            companyProfile.fein.value = undefined;
                            companyProfile.fein.errMsg = "";
                          } else {
                            companyProfile.fein.value =
                              value[0] + value[1] + "-" + value.slice(2);
                            companyProfile.fein.errMsg = "";
                          }

                          // Add the logic from feinAgencyMapCheck here
                          let convertedStr = companyProfile.fein.value;
                          let feinAgencyMap = JSON.parse(
                            sessionStorage.getItem("feinAgencyMap")
                          );
                          let isLoggedIn;
                          let agency_code;
                          try {
                            isLoggedIn = await Auth.currentAuthenticatedUser();
                            agency_code = isLoggedIn?.attributes[
                              "custom:agency_code"
                            ]
                              ? isLoggedIn?.attributes["custom:agency_code"]
                              : "";
                          } catch (error) {
                            console.log("Authentication error here", error);
                          }

                          if (
                            feinAgencyMap &&
                            feinAgencyMap[agency_code] &&
                            feinAgencyMap[agency_code].includes(convertedStr)
                          ) {
                            companyProfile.fein.error = true;
                          } else {
                            companyProfile.fein.error = false;
                          }

                          this.setState({ companyProfile });
                        }}
                      />
                      <p className="errMsg">
                        {companyProfile.fein &&
                        companyProfile.fein.errMsg &&
                        companyProfile.fein.errMsg !== ""
                          ? companyProfile.fein.errMsg
                          : ""}
                      </p>
                      {companyProfile.fein.error && (
                        <div style={{ color: "red" }}>
                          This FEIN already exists.
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-lg-5 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor="firstName"
                        title="First Name"
                        // className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Contact Name
                        </b>
                      </label>
                      <input
                        placeholder="Contact Name"
                        value={
                          companyProfile.firstName &&
                          companyProfile.firstName.value
                            ? companyProfile.firstName.value
                            : ""
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "firstName")
                        }
                        onKeyDown={(event) => this.handleKeyDown(event)}
                        style={
                          companyProfile.firstName &&
                          companyProfile.firstName.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        id="firstName"
                        type="text"
                        // onKeyUp={onlyAlpha}
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    {/* <Form.Group>
                      <label
                        htmlFor="lastName"
                        title="Last Name"
                        className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Last Name
                        </b>
                      </label>
                      <input
                        placeholder="Last Name"
                        className="mandatory"
                        value={
                          companyProfile.lastName &&
                          companyProfile.lastName.value
                            ? companyProfile.lastName.value
                            : ""
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "lastName")
                        }
                        style={
                          companyProfile.lastName &&
                          companyProfile.lastName.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        id="lastName"
                        type="text"
                        // onKeyUp={onlyAlpha}
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                      </Form.Group>*/}

                    <Form.Group>
                      <label
                        htmlFor="phoneNumber"
                        //  className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Contact Phone
                        </b>
                      </label>
                      <NumberFormat
                        value={
                          companyProfile.phoneNumber &&
                          companyProfile.phoneNumber.value
                            ? companyProfile.phoneNumber.value
                            : ""
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "phoneNumber")
                        }
                        style={
                          companyProfile.phoneNumber &&
                          companyProfile.phoneNumber.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="(000) 000-0000"
                        autoComplete="off"
                        id="phoneNumber"
                        format="(###) ###-####"
                        type="text"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                </div>
                <div className="row" id="company-profile-row3">
                  <div
                    id="entity-type"
                    style={{ width: "30%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label htmlFor="entityType" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Entity Type
                        </b>
                      </label>
                      <select
                        value={
                          companyProfile.entityType &&
                          companyProfile.entityType.value
                            ? companyProfile.entityType.value
                            : ""
                        }
                        id="entityType"
                        type="text"
                        style={
                          companyProfile.entityType &&
                          companyProfile.entityType.error
                            ? { borderColor: "red" }
                            : { fontSize: "16px" }
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "entityType")
                        }
                        autoComplete="off"
                      >
                        <option key={1} value={""}>
                          {" "}
                          Please Select{" "}
                        </option>
                        <option key={2} value={"Corporation"}>
                          {" "}
                          Corporation{" "}
                        </option>

                        <option key={3} value={"LLC"}>
                          {" "}
                          LLC{" "}
                        </option>

                        <option key={4} value={"Partnership"}>
                          {" "}
                          Partnership{" "}
                        </option>
                        <option key={5} value={"Sole Proprietor"}>
                          {" "}
                          Sole Proprietor{" "}
                        </option>
                      </select>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div
                    id="years-in-business"
                    style={{ width: "20%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label
                        htmlFor="yearsInBusiness"
                        title="Years in Business"
                        className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold text-nowrap ">
                          Years in Business
                        </b>
                      </label>
                      <span id="yearsInBusiness-span">
                        <NumberFormat
                          value={
                            companyProfile.yearsInBusiness &&
                            companyProfile.yearsInBusiness.value
                              ? companyProfile.yearsInBusiness.value
                              : ""
                          }
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "yearsInBusiness")
                          }
                          // onBlur={(event) => {
                          //   this.historicalonBlurHandler(event);
                          // }}
                          style={
                            companyProfile.yearsInBusiness &&
                            companyProfile.yearsInBusiness.error
                              ? { borderColor: "red", textAlign: "center" }
                              : { textAlign: "center" }
                          }
                          autoComplete="off"
                          id="yearsInBusiness"
                          format="####"
                          type="text"
                        />
                      </span>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>

                  <div
                    id="entity-type"
                    style={{ width: "30%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <Form.Label
                        htmlFor="Employer_Liability_Limit"
                        className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Employer's Liability Limit
                        </b>
                      </Form.Label>
                      <select
                        value={
                          companyProfile.empLiabilityLimit &&
                          companyProfile.empLiabilityLimit.value
                            ? companyProfile.empLiabilityLimit.value
                            : ""
                        }
                        id="empLiabilityLimit"
                        type="text"
                        style={
                          companyProfile.empLiabilityLimit &&
                          companyProfile.empLiabilityLimit.error
                            ? { borderColor: "red" }
                            : { fontSize: "16px" }
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "empLiabilityLimit")
                        }
                        autoComplete="off"
                      >
                        <option key={1} value={""}>
                          Please Select
                        </option>
                        <option key={2} value={"100-100-500"}>
                          {" "}
                          100/500/100{" "}
                        </option>
                        <option key={3} value={"500-500-500"}>
                          {" "}
                          500/500/500{" "}
                        </option>
                        <option key={3} value={"1000-1000-1000"}>
                          {" "}
                          1000/1000/1000{" "}
                        </option>
                      </select>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>

                  <div
                    id="expected-start-date"
                    style={{ width: "20%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label
                        htmlFor="effectiveDate"
                        title="Expected Start Date"
                        className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Proposed Eff.Date
                        </b>
                      </label>
                      <span id="effectiveDate">
                        <DatePicker
                          selected={
                            companyProfile?.effectiveDate?.value
                            // ? companyProfile?.effectiveDate.value
                            // : moment()
                          }
                          onChange={this.handleDateChangeEff}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          // style={
                          //   companyProfile?.effectiveDate?.error
                          //     ? { border: "1px solid red !important", textAlign: "center" }
                          //     : { border: "1px solid red !important", textAlign: "center" }
                          // }
                          id={
                            companyProfile?.effectiveDate?.error
                              ? "expectedStartDate1"
                              : ""
                          }
                        />
                      </span>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div
                    id="street-2"
                    style={{ width: "51%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label htmlFor="street2" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Mailing Address
                        </b>
                      </label>
                      <input
                        value={
                          companyProfile.street2 && companyProfile.street2.value
                            ? companyProfile.street2.value
                            : ""
                        }
                        id="street2"
                        type="text"
                        style={
                          companyProfile.street2 && companyProfile.street2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "street2")
                        }
                        onBlur={(event) => {
                          this.sameAsPrimaryCheckHandler(
                            event,
                            "street2",
                            "street1"
                          );
                        }}
                        placeholder="Street Address"
                        autoComplete="off"
                        className="rectangle1"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "11%", paddingInline: "15px" }}
                    id="zip-2"
                  >
                    <Form.Group>
                      <label
                        htmlFor={"zipCode2"}
                        className="mandatory"
                        title="Zip Code"
                      >
                        <b className="font-family-montserrat-semi-bold">Zip</b>
                      </label>
                      <NumberFormat
                        name="zipCode2"
                        id={"zipCode2"}
                        format="#####"
                        type="text"
                        onChange={(event) => {
                          this.updateCompanyProfile(event, "zipCode2");
                        }}
                        value={
                          companyProfile.zipCode2
                            ? companyProfile.zipCode2.value
                            : ""
                        }
                        style={
                          companyProfile.zipCode2 &&
                          companyProfile.zipCode2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onBlur={(event) => {
                          this.setStateAndCity2(event.target.value);
                          this.sameAsPrimaryCheckHandler(
                            event,
                            "zipCode2",
                            "zipCode1"
                          );
                        }}
                        placeholder="Zip"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div
                    id="city-2"
                    style={{ width: "27%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label
                        htmlFor={"cityName2"}
                        className="mandatory"
                        title="City"
                      >
                        <b className="font-family-montserrat-semi-bold">City</b>
                      </label>
                      <input
                        id={"cityName2"}
                        name="cityName2"
                        type="text"
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "cityName2")
                        }
                        onBlur={(event) => {
                          this.sameAsPrimaryCheckHandler(
                            event,
                            "cityName2",
                            "cityName1"
                          );
                        }}
                        value={
                          companyProfile.cityName2 &&
                          companyProfile.cityName2.value
                            ? companyProfile.cityName2.value
                            : ""
                        }
                        style={
                          companyProfile.cityName2 &&
                          companyProfile.cityName2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="City Name"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "11%", paddingInline: "15px" }}
                    id="state-2"
                  >
                    <Form.Group>
                      <label className="mandatory" title="State">
                        <b className="font-family-montserrat-semi-bold">
                          State
                        </b>
                      </label>
                      <select
                        id={"state2"}
                        name="state2"
                        type="text"
                        onChange={(event) => {
                          this.updateCompanyProfile(event, "state2");
                          this.sameAsPrimaryCheckHandler(
                            event,
                            "state2",
                            "state1"
                          );
                        }}
                        value={
                          companyProfile.state2 && companyProfile.state2.value
                            ? companyProfile.state2.value.toLowerCase()
                            : ""
                        }
                        style={
                          companyProfile.state2 && companyProfile.state2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        autoComplete="off"
                      >
                        {allStates2}
                      </select>
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div className="col-sm-1"></div>
                </div>

                <div className="row">
                  <div
                    id="street-1"
                    style={{ width: "51%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label htmlFor="street1" style={{ display: "flex" }}>
                        <b className="font-family-montserrat-semi-bold">
                          Primary Address
                          {<span style={{ color: "red" }}>&#42;</span>}
                        </b>
                        <div className="col-6 d-flex align-items-center">
                          <input
                            className="mr-1"
                            type="checkbox"
                            id="sameAsPrimary-1"
                            name={"sameAsPrimary"}
                            checked={sameAsPrimary}
                            onClick={this.handleSameAsPrimaryCheck}
                          />
                          <label
                            htmlFor="sameAsPrimary-1"
                            className="text-wrap"
                          >
                            <small>Same as Mailing address</small>
                          </label>
                        </div>
                      </label>
                      <input
                        value={
                          companyProfile.street1 && companyProfile.street1.value
                            ? companyProfile.street1.value
                            : ""
                        }
                        id="street1"
                        type="text"
                        disabled={sameAsPrimary}
                        style={
                          companyProfile.street1 && companyProfile.street1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "street1")
                        }
                        placeholder="Street Address"
                        autoComplete="off"
                        className="rectangle1"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div
                    id="zip-1"
                    style={{ width: "11%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label
                        htmlFor={"zipCode1"}
                        className="mandatory"
                        title="Zip Code"
                      >
                        <b className="font-family-montserrat-semi-bold">Zip</b>
                      </label>
                      <NumberFormat
                        name="zipCode1"
                        id={"zipCode1"}
                        format="#####"
                        type="text"
                        disabled={sameAsPrimary}
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "zipCode1")
                        }
                        value={
                          companyProfile.zipCode1
                            ? companyProfile.zipCode1.value
                            : ""
                        }
                        onBlur={(event) =>
                          this.setStateAndCity1(event.target.value, "FL")
                        }
                        style={
                          companyProfile.zipCode1 &&
                          companyProfile.zipCode1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="Zip"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div
                    id="city-1"
                    style={{ width: "27%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label
                        htmlFor={"cityName1"}
                        className="mandatory"
                        title="City"
                      >
                        <b className="font-family-montserrat-semi-bold">City</b>
                      </label>
                      <input
                        id={"cityName1"}
                        name="cityName1"
                        disabled={sameAsPrimary}
                        type="text"
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "cityName1")
                        }
                        value={
                          companyProfile.cityName1
                            ? companyProfile.cityName1.value
                            : ""
                        }
                        style={
                          companyProfile.cityName1 &&
                          companyProfile.cityName1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="City Name"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "11%", paddingInline: "15px" }}
                    id="state-1"
                  >
                    <Form.Group>
                      <label className="mandatory" title="State">
                        <b className="font-family-montserrat-semi-bold">
                          State
                        </b>
                      </label>
                      <select
                        id={"state1"}
                        name="state1"
                        type="text"
                        disabled={sameAsPrimary}
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "state1")
                        }
                        value={
                          companyProfile.state1 && companyProfile.state1.value
                            ? companyProfile.state1.value.toLowerCase()
                            : ""
                        }
                        style={
                          companyProfile.state1 && companyProfile.state1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        autoComplete="off"
                      >
                        {allStates1}
                      </select>
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
              </div>
            )}

            <div className="row notes-row">
              <Form.Group>
                <div className="notes">
                  {/*<label>
                    <b className="font-family-montserrat-semi-bold"> Notes </b>
                      </label>*/}
                  <textarea
                    defaultValue={companyProfile?.notes?.value || ""}
                    onBlur={(e) => {
                      companyProfile.notes = {
                        value: e.target.value,
                      };
                      this.setState({ companyProfile: companyProfile });
                    }}
                    rows="4"
                    placeholder="NOTES if any"
                    style={{ width: "100%" }}
                  ></textarea>
                </div>
              </Form.Group>
            </div>

            <div className="mt-3 mb-3">
              <AdditonalEntity
                additionalEntity={additionalEntity}
                setAdditionalEntityHandler={this.setAdditionalEntityHandler}
                addAdditionalEntityHandler={this.addAdditionalEntityHandler}
                removeAdditionalEntityHandler={
                  this.removeAdditionalEntityHandler
                }
                setAdditionalEntityvalueHandler={
                  this.setAdditionalEntityvalueHandler
                }
                setAdditionalEntityFein={this.setAdditionalEntityFein}
              />
            </div>
            <div className="pink-header mt-3">
              <div className="font-family-montserrat-bold">
                Locations &amp; Payroll
              </div>
              {/* {showLocationAndPayroll ? (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (showLocationAndPayroll)
                      this.setState({ showLocationAndPayroll: false });
                    else return;
                  }}
                >
                  &#8722;
                </span>
              ) : (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (!showLocationAndPayroll)
                      this.setState({ showLocationAndPayroll: true });
                    else return;
                  }}
                >
                  &#43;
                </span>
              )} */}
              {/* <div className="col-9 d-flex align-items-center">
                <input
                  className="mr-1"
                  type="checkbox"
                  id="locSameAsPrimary-1"
                  name={"locSameAsPrimary"}
                  checked={locSameAsPrimary}
                  onClick={this.handleLocSameAsPrimaryCheck}
                />
                <label
                  htmlFor="locSameAsPrimary-1"
                  className="text-wrap locSameAsPrimary-1-label"
                >
                  <small>Same as Physical Address</small>
                </label>
                </div>*/}
            </div>
            {showLocationAndPayroll && (
              <div className="calculator location-payroll-container">
                <div id="allLocations">
                  {/* <span className="formTitle">
                  <h4 className="company-locations">
                    <b>Exposure Details</b>
                  </h4>
                </span> */}
                  <div id="addressDiv">
                    {childrenLoc &&
                      Object.values(childrenLoc).map((e) => {
                        index++;
                        return (
                          <>
                            <CompLoc
                              index={index}
                              key={e.key}
                              locationInfo={e}
                              updateLocationInfo={this.updateLocationInfo}
                              setStateAndCity={this.setStateAndCity}
                              removeClassCode={this.removeClassCode}
                              removeLocation={this.onrmvChild}
                              updateClassCodesInfo={this.updateClassCodesInfo}
                              updateLocationError={this.updateLocationError}
                              resetClassCodeDescriptionData={
                                this.resetClassCodeDescriptionData
                              }
                              effectiveDate={companyProfile.effectiveDate}
                              locationCount={locationCount}
                              isLoggedIn={isLoggedIn}
                              govCCPremiumHandler={this.govCCPremiumHandler}
                            />
                            <hr style={{ marginBottom: "2rem" }}></hr>
                          </>
                        );
                      })}
                  </div>
                </div>
                {/* <div className="">
                  <button
                    type="button"
                    className="btnInvisible addLoc transpButton p-0"
                    onClick={this.onAddChildLoc}
                  >
                    <img src={require("../../../images/plus4.png")} />{" "}
                    <b>Add location</b>
                  </button>
                    </div>*/}
                {/*(salespersonCheck || showAllFields) && (
                  <div
                    className="d-flex requstedAdminRow"
                    style={{ marginTop: "20px", alignItems: "center" }}
                  >
                    <div className="font-family-montserrat-semi-bold d-flex align-items-center requestedAdminContent">
                      Requested Admin
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="requested-pricing-radio-yes"
                        name="requested-pricing-radio"
                        checked={requestedPricing.checked}
                        onChange={() => {
                          requestedPricing.checked = true;
                          this.setState({ requestedPricing });
                        }}
                      />
                      <Form.Group style={{ marginLeft: "10px" }}>
                        <label htmlFor={"adminPercent"} title="Per CK $">
                          <b className="font-family-montserrat-semi-bold">
                            Percent
                          </b>
                        </label>
                        <div className="d-flex adminPercent-container">
                          <NumberFormat
                            name="adminPercent"
                            id={"adminPercent"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            // suffix="%"
                            type="text"
                            disabled={!requestedPricing.checked}
                            onChange={(event) => {
                              requestedPricing.adminPercent =
                                event.target.value;
                              this.setState({ requestedPricing });
                            }}
                            style={
                              requestedPricing.checked
                                ? {}
                                : { backgroundColor: "#c2c4c5" }
                            }
                            value={
                              requestedPricing
                                ? requestedPricing.adminPercent
                                : ""
                            }
                            placeholder="0.00"
                            autoComplete="off"
                          />
                          <div
                            className="adminPercent-sign"
                            style={
                              requestedPricing.checked
                                ? {}
                                : { backgroundColor: "#c2c4c5" }
                            }
                          >
                            %
                          </div>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="d-flex align-items-center ">
                      <input
                        type="radio"
                        id="requested-pricing-radio-no"
                        name="requested-pricing-radio"
                        checked={!requestedPricing.checked}
                        onChange={() => {
                          requestedPricing.checked = false;
                          this.setState({ requestedPricing });
                        }}
                      />
                      <Form.Group>
                        <label htmlFor={"perCKdollar"} title="Per CK $">
                          <b className="font-family-montserrat-semi-bold">
                            Per CK $
                          </b>
                        </label>
                        <div className="adminPercent-container">
                          <NumberFormat
                            name="perCKdollar"
                            id={"perCKdollar"}
                            type="text"
                            disabled={requestedPricing.checked}
                            onChange={(event) => {
                              requestedPricing.perCKdollar = event.target.value;
                              this.setState({ requestedPricing });
                            }}
                            style={
                              requestedPricing.checked
                                ? { backgroundColor: "#c2c4c5" }
                                : {}
                            }
                            value={
                              requestedPricing
                                ? requestedPricing.perCKdollar
                                : ""
                            }
                            thousandSeparator={true}
                            prefix={"$"}
                            placeholder="$ 0,0"
                            autoComplete="off"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                          ) */}

                {/*!requestedPricing.checked &&
                (salespersonCheck || mailCampaignUserCheck || !isLoggedIn) ? (
                  <div>
                    <b>
                      <Form.Check
                        inline
                        id="Weekly_Rate"
                        value="a"
                        name=""
                        checked={requestedPricing["WeeklySelect"] === "a"}
                        label="Weekly Rate"
                        type="radio"
                        onChange={(e) =>
                          this.handleOptionChange(e, "response", "a")
                        }
                      />
                      <Form.Check
                        inline
                        id="Bi-Weekly"
                        value="b"
                        name=""
                        checked={requestedPricing["WeeklySelect"] === "b"}
                        label="Bi-Weekly"
                        type="radio"
                        onChange={(e) =>
                          this.handleOptionChange(e, "response", "b")
                        }
                      />
                      <Form.Check
                        inline
                        id="Semi-Monthly"
                        value="c"
                        name=""
                        checked={requestedPricing["WeeklySelect"] === "c"}
                        label="Semi-Monthly"
                        type="radio"
                        onChange={(e) =>
                          this.handleOptionChange(e, "response", "c")
                        }
                      />
                      <Form.Check
                        inline
                        id="Monthly"
                        value="d"
                        name="Monthly"
                        checked={requestedPricing["WeeklySelect"] === "d"}
                        label="Monthly"
                        type="radio"
                        onChange={(e) =>
                          this.handleOptionChange(e, "response", "d")
                        }
                      />
                    </b>
                  </div>
                ) : (
                  ""
                ) */}
              </div>
            )}
            {/*!mailCampaignUserCheck &&
              !isUnderWriterFlow &&
              !salespersonCheck &&
              isLoggedIn && (
                <div className="pink-header">
                  <div className="font-family-montserrat-bold">
                    Additional Questions
                  </div>
                  {showAdditionalQuestions ? (
                    <span
                      className="pink-sign noselect"
                      onClick={() => {
                        if (showAdditionalQuestions)
                          this.setState({ showAdditionalQuestions: false });
                        else return;
                      }}
                    >
                      &#8722;
                    </span>
                  ) : (
                    <span
                      className="pink-sign noselect"
                      onClick={() => {
                        if (!showAdditionalQuestions)
                          this.setState({ showAdditionalQuestions: true });
                        else return;
                      }}
                    >
                      &#43;
                    </span>
                  )}
                </div>
                    ) */}
            {/*salespersonCheck || showAllFields ? (
              <BrokerQues
                brokerQuestionValue={
                  this.state.brokerDetails.brokerQuestionValue
                }
                brokerdropdownSelected={
                  this.state.brokerDetails.brokerdropdownSelected
                }
                brokerPercentage={this.state.brokerDetails.brokerPercentage}
                brokerQuestionResponse={this.brokerQuestionResponse}
                brokerDropdownResponse={this.brokerDropdownResponse}
                brokerPercentageResponse={this.brokerPercentageResponse}
              />
            ) : (
              ""
            ) */}
            {/* salespersonCheck || showAllFields ? (
              <div
                className="table-responsive question-list-container"
                id="companyproQuestPEOPaygo"
              >
                <Allques
                  quesList={[this.state.companyProfileAddQuestions[2]]}
                  updateQuesList={this.updateQuesList_sales_flow}
                  header={false}
                />
              </div>
            ) : (
              !mailCampaignUserCheck &&
              !isUnderWriterFlow &&
              isLoggedIn &&
              showAdditionalQuestions && (
                <div className="table-responsive question-list-container">
                  <Allques
                    quesList={this.state.companyProfileAddQuestions}
                    updateQuesList={this.updateQuesList}
                    header={false}
                  />
                </div>
              )
              ) */}
            {/*salespersonCheck || mailCampaignUserCheck || !isLoggedIn ? (
              <div
                className="table-responsive question-list-container"
                id="companyprofilequestion"
              >
                {this.state.companyProfileAddQuestions.map((tabName, index) =>
                  index > 2 ? (
                    <Allques
                      quesList={[this.state.companyProfileAddQuestions[index]]}
                      updateQuesList={this.updateLastThreeQuesList}
                      header={false}
                    />
                  ) : (
                    ""
                  )
                )}
              </div>
            ) : (
              ""
            ) */}
            {/* <div className="emod-ques">
              {Object.values(emodStatesData).length > 0 && (
                <h4 className="emod-ques-heading">
                  <b>Experience Modification</b>
                </h4>
              )}
              {Object.keys(emodStatesData).map((e) => {
                return (
                  <EmodQuestions
                    key={e}
                    state={e}
                    emodData={emodStatesData[e]}
                    updateEmodInfo={this.updateEmodInfo}
                  />
                );
              })}
            </div> */}

            {/*<p className="formTitle title3">
              Prior Carrier Information/Loss History
            </p>
            {historicalClaimsError && (
              <p id="claims-error" className="text-danger">
                Claims submitted should be for consecutive years!
              </p>
            )}
            {/* <hr /> 
            <div className="row grey-table-row ">
              <table id="claimTable" className="grey-table">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>{table}</tbody>
              </table>
            </div>*/}

            <div>
              <div
                id="emod_container"
                className="d-flex align-items-center mt-4 mb-4"
              >
                <h5 className="pr-4 font-family-montserrat-semi-bold ">
                  {" "}
                  Experience Modification
                </h5>
                <span className="d-flex align-items-baseline pr-4 pl-4">
                  <NumberFormat
                    name="emod"
                    id={"emod"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    onKeyDown={(event) => {
                      if (event.key === "-" || event.key === "−") {
                        event.preventDefault(); // Prevent typing the '-' character
                      }
                    }}
                    // suffix="%"
                    type="text"
                    value={emod?.["emodValue"]}
                    placeholder="Rate"
                    autoComplete="off"
                    style={{ width: "60%", textAlign: "center" }}
                    onChange={(e) => this.emodChangeHandler(e)}
                  />
                </span>
              </div>
            </div>
            <div className="mt-4 mb-4">
              <IncludeExclude
                includedExcludedObject={includedExcludedObject}
                addIncludeExcludeHandler={this.addIncludeExcludeHandler}
                removeIncludeExcludeHandler={this.removeIncludeExcludeHandler}
                setincludeExcludeValueHandler={
                  this.setincludeExcludeValueHandler
                }
                handleKeyDown={this.handleKeyDown}
                checkEligibleForClasscodeHandler={
                  this.checkEligibleForClasscodeHandler
                }
              />
            </div>
          </form>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button
                type="button"
                onClick={() => {
                  this.submitCompPro();
                }}
                disabled={!agentpersonCheck}
                id="profileSaveButton"
                className="btn mt-0 button_color btn-lg btn-warning-shadow btnSubmits "
              >
                Continue
              </button>

              <p className="errMsg" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  onAddChildLoc = (data) => {
    let { childrenLoc } = this.state;
    let dupChildrenLoc = childrenLoc;
    console.log(dupChildrenLoc, " chkeic on clidk");
    let rand = Math.random();
    const keys = Object.keys(dupChildrenLoc);
    const index = parseInt(keys[keys.length - 1]) + 1;
    if (!data.location_details) {
      data.location_details = {};
    }
    dupChildrenLoc[index] = {
      number: index,
      key: rand,
      classCodesInfo: { 0: {} },
      state: { value: "fl" },
      zipCode: {},
      cityName: {},
      street: {},
      suta: {},
      clientsuta: {},
    };
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
  };

  onrmvChild = (addressId) => {
    let { childrenLoc, emodStatesData, state_class_code } = this.state;

    if (!childrenLoc[addressId].state || !childrenLoc[addressId].state.value) {
      delete childrenLoc[addressId];
      this.setState({ childrenLoc });
      return;
    }

    let stateDeleted = childrenLoc[addressId].state.value;
    let stateDoesNotExistAgain = true;
    let firstToBeDisabledIndex;

    //cc_delete
    for (let classCodeIndex in childrenLoc[addressId].classCodesInfo) {
      let locationIndex = addressId;
      let currState = childrenLoc[locationIndex].state.value || "";
      if (
        currState.toLowerCase() in state_class_code &&
        state_class_code[currState.toLowerCase()].val
      ) {
        try {
          let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
            classCodeIndex
          ]["classCodeDescription"]
            ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                "classCodeDescription"
              ].value
            : undefined;
          if (class_code_desc) {
            let class_code = class_code_desc.split(":")[0].trim();
            if (
              state_class_code[currState.toLowerCase()].cc_dsc &&
              class_code in state_class_code[currState.toLowerCase()].cc_dsc
            ) {
              let locToUpdate, ccToUpdate;
              for (let locIndx in childrenLoc) {
                if (childrenLoc[locIndx].state.value === currState) {
                  let ccInfo = childrenLoc[locIndx].classCodesInfo;
                  for (let ccIndx in ccInfo) {
                    let cc_desc;
                    try {
                      cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                    } catch (e) {}
                    if (cc_desc) {
                      let cc = cc_desc.split(":")[0].trim();
                      if (Number(cc) === Number(class_code)) {
                        childrenLoc[locIndx].classCodesInfo[ccIndx][
                          "pricing"
                        ] = {
                          value:
                            state_class_code[currState.toLowerCase()].cc_dsc[
                              class_code
                            ],
                          disabled: true,
                        };
                        if (
                          !locToUpdate &&
                          !ccToUpdate &&
                          Number(locIndx) !== Number(locationIndex)
                        ) {
                          locToUpdate = locIndx;
                          ccToUpdate = ccIndx;
                        }
                      }
                    }
                  }
                }
              }
              if (locToUpdate && ccToUpdate) {
                childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                  "pricing"
                ].disabled = false;
                if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
                  state_class_code[currState.toLowerCase()].cc_dsc = {};
                }
                try {
                  state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                    childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                      "pricing"
                    ].value;
                } catch (error) {}
              } else {
                delete state_class_code[currState.toLowerCase()].cc_dsc[
                  class_code
                ];
              }
            }
          }
        } catch (error) {}
      }
    }
    //cc_delete

    delete childrenLoc[addressId];

    let stateList = [];
    try {
      for (let addressBlockNo in childrenLoc) {
        if (childrenLoc[addressBlockNo].state.value) {
          if (childrenLoc[addressBlockNo].state.value === stateDeleted) {
            childrenLoc[addressBlockNo].suta.disabled = true;
            childrenLoc[addressBlockNo].clientsuta.disabled = true;
            if (!firstToBeDisabledIndex)
              firstToBeDisabledIndex = addressBlockNo;
            stateDoesNotExistAgain = false;
          }
          stateList.push(childrenLoc[addressBlockNo].state.value.toLowerCase());
        }
      }

      if (stateDoesNotExistAgain) {
        state_class_code[stateDeleted.toLowerCase()].val = "";
      }
      if (firstToBeDisabledIndex) {
        childrenLoc[firstToBeDisabledIndex].suta.disabled = false;
        childrenLoc[firstToBeDisabledIndex].clientsuta.disabled = false;
      }
    } catch (error) {}

    if (stateList.length > 0) {
      let statesData = {
        ncciStates: [],
        actingNcciStates: [],
        indBureauStates: [],
      };
      axios
        .post(
          awsUrl2 + "/api/getExpModStateCategory",
          JSON.stringify({ states: stateList })
        )
        .then((response) => {
          statesData = response.data;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          // let newEmodData = getEmodStatesData(statesData, emodStatesData || {});
          this.setState({
            childrenLoc,
            // emodStatesData: newEmodData,
            state_class_code,
          });
        });
    } else {
      // emodStatesData = {};
      this.setState({
        childrenLoc,
        // emodStatesData,
        state_class_code,
      });
    }
  };
}

export default function FormCompProWrapper(props) {
  return (
    <Context.Consumer>
      {(context) => (
        <FormCompPro
          updateMailCampaignUser={props.updateMailCampaignUser}
          quoteSubmitted={context.quoteSubmitted}
          setQuoteSubmittedStatus={context.setQuoteSubmittedStatus}
          setEligibilityCheck={context.setEligibilityCheck}
          eligibilityCheck={context.eligibilityCheck}
          feinAgnecyMap={context.feinAgnecyMap}
        />
      )}
    </Context.Consumer>
  );
}
